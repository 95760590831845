<template>
  <div class="operationTable">
    <el-table
      v-if="workRead || projectRead || operationRead"
      :class="{ show_empty: showEmpty }"
      v-loading="tableLoading"
      :data="tableData"
      :max-height="tableHeight"
      border
      style="width: 100%"
      empty-text=" "
      :row-key="'id'"
      default-expand-all
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      header-cell-class-name="header-row-cell"
    >
      <el-table-column
        v-for="(item, idx) in tableOperations"
        :key="idx"
        :label="$t('projectManagement.' + item.label + '')"
        align="left"
        :min-width="item.width"
      >
        <template slot="header" v-if="item.type === 'input'">
          <span
            >{{ $t('projectManagement.' + item.label + '') }}&nbsp;&nbsp;
            <el-tooltip class="item" effect="dark" content="可编辑" placement="top-start">
              <i class="el-icon-edit"></i>
            </el-tooltip>
          </span>
        </template>
        <template slot="header" v-if="item.type === 'inputNumber'">
          <span
            >{{ $t('projectManagement.' + item.label + '') }}&nbsp;&nbsp;
            <el-tooltip class="item" effect="dark" content="可编辑" placement="top-start">
              <i class="el-icon-edit"></i>
            </el-tooltip>
          </span>
        </template>
        <template slot="header" v-if="item.type === 'select'">
          <span
            >{{ $t('projectManagement.' + item.label + '') }}&nbsp;&nbsp;
            <el-tooltip class="item" effect="dark" content="可编辑" placement="top-start">
              <i class="el-icon-edit"></i>
            </el-tooltip>
          </span>
        </template>
        <template slot="header" v-if="item.desc">
          <span class="showTipsColumnSpan"
            >{{ $t('projectManagement.' + item.label + '') }}&nbsp;&nbsp;
            <el-popover placement="top-start" trigger="hover" class="showTips">
              <span> {{ item.desc }}</span>
              <el-button
                :class="['unactived', { actived: hoverType }]"
                class="btn-miss"
                slot="reference"
                @mouseenter.native="hoverCheck(1)"
                @mouseleave="hoverCheck(0)"
              >
              </el-button>
            </el-popover>
          </span>
        </template>
        <template slot-scope="scope">
          <div v-if="!item.type">
            <div :class="item.label === 'OperationalServiceItem' && scope.row.justSettlement ? 'tips' : ''">
              <span v-if="isServiceItem && scope.row.children && !scope.row.children.length"></span>
              <span>{{ scope.row[item.value] }}</span>
            </div>
          </div>
          <div v-else-if="item.type === 'input'" class="inputCommonStyle">
            <div class="scopeInputAccomplete" v-show="!inputTip(scope.$index)">
              <el-tooltip v-if="scope.row[item.key].length > 12" class="item" effect="dark" :content="scope.row[item.key]" placement="top-start">
                <div class="workInputShadow" @click="clickThisRow(scope.row, scope.$index)">
                  {{ scope.row[item.key] }}
                </div>
              </el-tooltip>
              <div v-else class="workInputShadow" @click="clickThisRow(scope.row, scope.$index)">
                {{ scope.row[item.key] }}
              </div>
            </div>
          </div>
          <div v-else-if="item.type === 'inputNumber'">
            <div class="inputItem">
              <template v-if="!inputTip(scope.$index)">
                <div class="inputShadow" @click="clickThisRow(scope.row, scope.$index)">
                  {{ scope.row[item.value] }}
                </div>
              </template>
            </div>
            <div v-if="inputShow && inputTip(scope.$index)" class="scope-input">
              <el-input @change="updateTaskData(scope.row, scope.$index)" v-model="scope.row[item.value]"> </el-input>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('projectManagement.operation')" label-class-name="confirmLabel" :align="'center'" width="190px" fixed="right">
        <template slot-scope="scope">
          <div class="operations">
            <!-- 运营 -->
            <span v-show="operationUpdate" @click="copyRow(scope.row)">复制合同信息到空行</span>

            <!-- 没有编辑服务项权限 -->
            <div v-if="!editServiceItemAuth && isServiceItem">
              <span v-show="isServiceItem" @click="deleteRow(scope.$index, scope.row)">{{ $t('projectManagement.delete') }}</span>
              <span v-show="workEdit" @click="$emit('createProjectWorkItem', scope.row, 'edit')">{{
                $t('projectManagement.createProWorkItem')
              }}</span>
            </div>
            <!-- 有编辑服务项权限 -->
            <div v-else-if="editServiceItemAuth && !isWorkShowItem" style="display: inline-block">
              <span v-show="rowEditServiceItem" @click="editServiceItem(scope.row)">{{ $t('projectManagement.editServiceItem') }}</span>
              <div v-show="!isWorkShowItem" class="serviceOperation">
                <el-popover placement="left" trigger="click" popper-class="popover">
                  <ul class="selectUl" style="text-align: center">
                    <li v-for="ids in operationItems" :key="ids" class="selectLi" @click="operationType(ids, scope.$index, scope.row)">
                      <span>{{ ids }}</span>
                    </li>
                  </ul>
                  <div class="pullMore" slot="reference">
                    <span class="text">...</span>
                  </div>
                </el-popover>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mixinsProjectStatement } from '@/mixins/authGroup';
import projectManagementApi from '@/api/modules/projectManagement.api.js';

export default {
  mixins: [mixinsProjectStatement],

  props: {
    tableHeight: {
      type: Number,
      default: 0,
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
    checkFirstGetWork: {
      type: Boolean,
      default: false,
    },
    typeHeaderModule: {
      type: String,
      default: '',
    },
    originTableData: {
      type: Array,
      default: () => {
        return [];
      },
    },

    serviceTableData: {
      type: Array,
      default: () => {
        return [];
      },
    },

    tableOperations: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  /* 待启用 */
  created() {
    this.checkAuthOperation();
  },
  activated() {
    this.checkAuthOperation();
  },
  data() {
    return {
      currentRow: {},
      inputShow: false,
      scopeIdx: null,
      isClick: false,
      operationItems: [],
      tableData: [],
      projectData: [],
      serviceId: '',
      resizeFlag: null,
      showEmpty: false,
      hoverType: 0,
    };
  },
  computed: {
    permissionsKeys() {
      return this.$store.state.app.userConfig.permissionsKeys || {};
    },
    routeName() {
      return this.$route.meta.name;
    },
  },
  watch: {
    originTableData: {
      deep: true,
      immediate: false,
      handler(val) {
        !val.length ? (this.showEmpty = true) : (this.showEmpty = false);

        this.tableData = [...val];
      },
    },
  },
  methods: {
    editServiceItem(row) {
      this.$emit('editShowDialog', row.id);
    },

    inputTip(idx) {
      return this.scopeIdx === idx;
    },
    clickThisRow(row, idx) {
      this.currentRow = row;
      this.scopeIdx = idx;
      this.inputShow = true;
    },

    updateTaskData(row, idx) {
      const findIdx = this.taskData.findIndex(val => {
        return val.id === row.id;
      });
      const currentItem = this.tableData[idx];
      if (!~findIdx) {
        this.taskData.push({
          id: currentItem.id,
          name: currentItem.name,
          itemId: currentItem.itemId,
          size: currentItem.size,
        });
      } else {
        this.taskData[findIdx] = {
          id: currentItem.id,
          name: currentItem.name,
          itemId: currentItem.itemId,
          size: currentItem.size,
        };
      }
    },
    checkAuthOperation() {
      this.operationItems = [];
      if (this.operationUpdate) {
        this.operationItems.push('编辑服务项');
      }
      this.operationItems.push('设置为据实结算');
      if (this.operationDelete) {
        this.operationItems.push('删除');
      }
    },
    operationType(val, index, rows) {
      switch (val) {
        case '删除':
          this.deleteRow(index, rows);
          break;
        case '编辑服务项':
          this.$emit('editShowDialog', rows.id);
          break;
        case '编辑任务项':
          this.$emit('editTaskDrawer', rows);
          break;
        case '新建项目工作项':
          if (this.workEdit) {
            this.$emit('createProjectWorkItem', rows, 'edit');
          }
          break;
        case '设置为据实结算':
          this.setJustSettlement(index, rows);
          break;
      }
    },
    setJustSettlement(index, rows) {
      this.$confirm('请确认将该服务项设置为“据实结算”，设置后不可撤销，请谨慎操作', '设置结算方式', {
        confirmButtonText: '设 置',
        cancelButtonText: '取 消',
      })
        .then(() => {
          this.isSetJust(index, rows);
        })
        .catch(() => {});
    },
    isSetJust(index, rows) {
      if (!rows.canSetJustSettlement && !rows.justSettlement) {
        this.$message.error('该服务项存在进度，无法设置据实结算');
        return;
      }
      if (!rows.canSetJustSettlement && rows.justSettlement) {
        this.$message.error('该服务项已是据实结算服务项，请勿重复设置');
        return;
      }
      this.$emit('setJustSettlement', rows.id);
    },

    isShow(bool, row) {
      if (bool) {
        this.isClick = true;
        this.serviceId = row.id;
      } else {
        this.isClick = false;
        this.serviceId = '';
      }
    },

    //复制合同信息到空行
    copyRow(row) {
      let flag = false;
      let filterTableData = this.tableData;
      for (let blankRow of filterTableData) {
        if (blankRow.children.length) {
          const childs = [...blankRow.children];
          childs.forEach((item, idx) => {
            if (item.projectCode === '' && row.projectCode !== '') {
              flag = true;
              blankRow.children[idx].projectCode = row.projectCode;
            }
            if (item.projectSeq === '' && row.projectSeq !== '') {
              flag = true;
              blankRow.children[idx].projectSeq = row.projectSeq;
            }
          });
        }
        if (blankRow.projectCode === '' && row.projectCode !== '') {
          flag = true;
          blankRow.projectCode = row.projectCode;
        }
        if (blankRow.projectSeq === '' && row.projectSeq !== '') {
          flag = true;
          blankRow.projectSeq = row.projectSeq;
        }
      }
      if (row.projectCode == '' && row.projectSeq == '') {
        this.$message.warning('复制内容为空！');
      } else if (flag == true) {
        this.$message.success('复制成功,点击保存后生效!');
        this.$emit('loadTableData', filterTableData);
      } else {
        this.$message.warning('没有空行！');
      }
    },

    deleteRow(index, rows) {
      this.$confirm('<p>此操作将删除该服务项, 是否继续?</p>', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true,
      }).then(async () => {
        const res = await projectManagementApi.verifyServiceItemTimeFilling(rows.id);
        if (res[0].exists) {
          this.$alert('该服务项已有工时，无法删除。', '提示', {
            confirmButtonText: '确定',
            type: 'error',
          });
          return;
        }
        if (res[0].scheduleExists) {
          this.$alert('该服务项已有进度，无法删除。', '提示', {
            confirmButtonText: '确定',
            type: 'error',
          });
          return;
        }
        this.$emit('deleteRow', rows.id);
      });
      /*         .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除！',
          });
        }); */
    },
    hoverCheck(item) {
      this.hoverType = item;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/compsStyle/projectManagementTable.scss';
.operationTable {
  @include projTableClass;
}
.serviceOperation {
  width: 16px;
  height: 16px;
  .pullMore {
    margin-top: -2px;
    margin-left: -12px;
  }
}
.tips::after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border: 4px solid #f59a23;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
</style>
<!-- 以下代码修改 popover 样式，不可以使用 scoped -->
<style lang="scss">
.popover {
  padding: 0;
  min-width: 120px;
  .selectUl {
    .selectLi {
      cursor: pointer;
      height: 30px !important;
      line-height: 30px !important;
      border-bottom: 1px solid #f3f4f5;
      span {
        font-size: 13px;
      }
    }
    .selectLi:hover {
      color: #2d8cf0;
      background-color: #ecf5ff;
    }
    .selectLi:last-child {
      border-bottom: none;
    }
  }
}
.showTipsColumnSpan {
  height: 32px;
  line-height: 32px;
  display: inline-block;
  .showTips {
    padding-top: 2px;
    .btn-miss {
      border: 0 !important;
      background: #f8f8f8f9;
    }
    .unactived {
      position: relative;
      cursor: pointer;
      padding: 0;
      border-radius: 4px;
      border: 1px solid #e8e8e8;
    }
    .actived {
      border: 1px solid rgba(24, 144, 255, 0.2);
    }
    .unactived::before {
      content: '';
      position: absolute;
      left: 20%;
      transform: translateY(-80%);
      width: 12px;
      height: 15px;
      background: url('~@/assets/tabIcon/tip.png') no-repeat;
      background-size: cover;
    }
    .unactived:hover::before {
      vertical-align: center;
      background: url('~@/assets/tabIcon/tip_actived.png') no-repeat;
      background-size: cover;
    }
  }
}
</style>
