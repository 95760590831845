<template>
  <div>
    <span class="spanTitle">{{ params.title }}</span>
    <el-tooltip class="item" effect="light" :content="params.tooltip" placement="top" popper-class="myTooltipPopper">
      <i class="el-icon-warning-outline"></i>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: 'headerComponent',
};
</script>

<style lang="scss">
.spanTitle {
  color: #515a6e;
  margin-right: 5px;
}
.el-icon-warning-outline {
  font-size: 14px;
}
.myTooltipPopper {
  color: #515a6e;
  background-color: #fff !important;
  border: 1px solid #ebeef5 !important;
  padding: 12px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.popper__arrow {
  border: 1px solid #ebeef5 !important;
}
</style>
