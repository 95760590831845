<template>
  <div class="editServiceTable" :style="{ height: tableContainerHeight }">
    <el-table
      ref="table"
      :class="{ show_empty: showEmpty }"
      v-loading="tableLoading"
      :data="serviceTableData"
      v-on="$listeners"
      @row-click="rowClick"
      highlight-current-row
      border
      :row-class-name="tableRowClassName"
      :row-style="{ height: 23 + 'px' }"
      empty-text=" "
      :max-height="tableHeight"
    >
      <el-table-column prop="dataIndex" :label="$t('commonVariables.sequenceNumber')" v-if="isIndex">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in chooseInputArr"
        :key="index + 50"
        :align="item.align || 'left'"
        :min-width="item.width ? item.width : '150'"
        :show-overflow-tooltip="item.tooltip"
      >
        <template #header>
          <span style="padding-right: 5px">{{ $t(applyComp + '.' + item.prop) }}</span>
        </template>
        <template slot-scope="scope">
          <div v-if="item.prop === 'status'" :style="{ background: circleStatus(scope.row[item.prop]) }" class="circle"></div>
          <span v-if="!choosedThisRow(scope.$index, item.prop, item.editable)">{{ $t(filterItem(item.prop, scope.row[item.prop])) }}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip :label="$t(applyComp + '.bdNames')" align="left" min-width="120">
        <template slot-scope="scope">
          <span v-for="(item, idx) in scope.row.bdNames" :key="item">
            {{ scope.row.bdNames.length === ++idx ? item : item + ', ' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" min-width="120">
        <template #header>
          <el-tooltip effect="dark" :content="$t(applyComp + '.preContractUrl')" placement="top-start">
            <span class="headerClass" style="padding-right: 5px">{{ $t(applyComp + '.preContractUrl') }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <a
            :class="['defaultCursor', { downloadUrl: scope.row.preContractUrls.length }]"
            :href="scope.row.preContractUrls ? scope.row.preContractUrls : '#'"
            :download="scope.row.preContractUrls ? scope.row.preContractUrls : false"
          >
            {{ $t('commonVariables.Download') }}
          </a>
        </template>

        <template slot-scope="scope">
          <el-popover placement="right" width="400" trigger="click">
            <el-table v-show="downloadPreUrls.length" border :data="downloadPreUrls" style="width: 100%">
              <el-table-column min-width="120" prop="name" label="合同名称"></el-table-column>
              <el-table-column min-width="120" label="">
                <template slot-scope="scope">
                  <a
                    style="cursor: pointer !important; color: #2d8cf0"
                    :href="scope.row.url ? scope.row.url : '#'"
                    :download="scope.row.url ? scope.row.url : false"
                  >
                    {{ $t('commonVariables.Download') }}
                  </a></template
                >
              </el-table-column>
            </el-table>
            <span
              slot="reference"
              :class="['defaultCursor', { downloadUrl: scope.row.preContractUrls.length }]"
              @click="translateDownData('preContractUrl', scope.row.preContractUrls)"
            >
              {{ $t('commonVariables.Download') }}</span
            >
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column align="center" min-width="120">
        <template #header>
          <el-tooltip effect="dark" :content="$t(applyComp + '.finalContractUrl')" placement="top-start">
            <span class="headerClass" style="padding-right: 5px">{{ $t(applyComp + '.finalContractUrl') }}</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-popover placement="right" width="400" trigger="click">
            <el-table v-show="downloadFinUrls.length" border :data="downloadFinUrls" style="width: 100%">
              <el-table-column min-width="120" prop="name" label="合同名称"></el-table-column>
              <el-table-column min-width="120" label="">
                <template slot-scope="scope">
                  <a
                    style="cursor: pointer !important; color: #2d8cf0"
                    :href="scope.row.url ? scope.row.url : '#'"
                    :download="scope.row.url ? scope.row.url : false"
                  >
                    {{ $t('commonVariables.Download') }}
                  </a></template
                >
              </el-table-column>
            </el-table>
            <span
              slot="reference"
              :class="['defaultCursor', { downloadUrl: scope.row.finalContractUrls.length }]"
              @click="translateDownData('finalContractUrl', scope.row.finalContractUrls)"
            >
              {{ $t('commonVariables.Download') }}</span
            >
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label-class-name="confirmLabel" :label="$t('commonVariables.operate')" fixed="right" align="center" min-width="120">
        <template slot-scope="scope">
          <div class="operations" fixed="right">
            <!-- <span v-if="readPermission" @click="checkRow(scope.$index, serviceTableData)" class="editRow">查看</span> -->
            <span v-if="authGroup.update" @click="editRow(scope.$index, serviceTableData)" class="editRow">{{ $t('commonVariables.edit') }}</span>
            <span v-if="authGroup.delete" @click="deleteRow(scope.$index, serviceTableData)" class="deleteRow">{{
              $t('commonVariables.delete')
            }}</span>
            <span v-if="!visitorAuth">无操作权限</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page"
      :page-sizes="[10, 20, 30, 50, 100]"
      :page-size="pagesize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  computed: {
    tableContainerHeight() {
      return this.tableHeight + 60 + 4 + 'px';
    },
    routeName() {
      return this.$route.meta.name;
    },
    filterItem() {
      return (type, val) => {
        if (type === 'status') {
          switch (val) {
            case 'doing':
              return 'contractManagement.doingProjectCount';
            case 'finish':
              return 'contractManagement.finishProjectCount';
          }
        } else if (type === 'contractType') {
          switch (val) {
            case 's':
              return 'contractManagement.sales';
            case 'p':
              return 'contractManagement.purchase';
          }
        } else {
          return val;
        }
      };
    },

    visitorAuth() {
      return Object.values(this.authGroup).find(val => val);
    },
  },
  props: {
    page: Number,
    currentPage: Number,
    pagesize: Number,
    count: Number,
    isIndex: {
      type: Boolean,
      default: true,
    },
    // 对应必填项的项目数组
    requiredIndex: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 必须唯一的的项目数组
    repeatFiled: {
      type: Array,
      default: () => {
        return [];
      },
    },
    bdVisable: {
      type: Boolean,
      default: false,
    },
    deleteWarnTips: {
      type: String,
      default: '',
    },
    reportType: {
      type: String,
      default: '',
    },
    // 复制行
    copyRow: {
      type: Boolean,
      default: false,
    },

    authGroup: {
      type: Object,
      default: () => {
        return {};
      },
    },
    saveKey: Boolean,
    applyComp: String,
    tableHeight: {
      type: Number,
      default: 0,
    },
    tableLoading: {
      type: Boolean,
      default: false,
    },
    updatePermission: {
      type: Boolean,
      default: false,
    },
    serviceTableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    chooseInputArr: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      preUrlData: [],
      downloadFinUrls: [],
      downloadPreUrls: [],
      id: '',
      showEmpty: false,
      isClick: false,
      serviceId: false,
      currentRoute: '',
    };
  },

  watch: {
    serviceTableData: {
      deep: true,
      immediate: false,
      handler(newVal) {
        !newVal.length && (this.showEmpty = true);
      },
    },

    saveKey(newVal) {
      if (newVal) {
        this.id = '';
      }
    },
  },

  methods: {
    translateUrl(url) {
      let finishUrl = new URL(url);
      finishUrl.hash = '';
      finishUrl.search = '';
      finishUrl = finishUrl.toString();
      const encodedUrl = decodeURI(finishUrl);
      return encodedUrl;
    },
    translateDownData(key, urlArr) {
      if (key === 'finalContractUrl' && urlArr.length) {
        this.downloadFinUrls = urlArr.map(val => {
          let finUrl = this.translateUrl(val);
          return { name: finUrl.split('/')[finUrl.split('/').length - 1], url: finUrl };
        });
      } else if (key === 'preContractUrl' && urlArr.length) {
        this.downloadPreUrls = urlArr.map(val => {
          let finUrl = this.translateUrl(val);
          return { name: finUrl.split('/')[finUrl.split('/').length - 1], url: finUrl };
        });
      } else {
        this.downloadPreUrls = [];
        this.downloadFinUrls = [];
      }
    },
    checkFollow(row) {
      this.$emit('checkFollow', row);
    },
    circleStatus(val) {
      switch (val) {
        case 'doing':
          return '#2D8CF0';
        case 'finish':
          return '#DA4F2A';
      }
    },
    isShow(bool, row) {
      if (bool) {
        this.isClick = true;
        this.serviceId = row.id;
      } else {
        this.isClick = false;
        this.serviceId = '';
      }
    },
    handleSizeChange(val) {
      console.log(val);

      this.$emit('pageChange', 'size', val);
    },
    handleCurrentChange(val) {
      this.$emit('pageChange', 'current', val);
    },

    recordProps(item) {
      this.$emit('record', item);
    },

    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
      if (this.requiredIndex.includes(rowIndex) || this.repeatFiled.includes(rowIndex)) {
        return 'warning-row';
      }
    },

    async checkRow(index, rows) {
      this.$emit('readServiceItem', rows[index]);
    },
    async editRow(index, rows) {
      this.$emit('editServiceItem', rows[index]);
    },
    async deleteRow(index, rows) {
      this.$confirm(this.deleteWarnTips, this.$t('commonVariables.notice'), {
        confirmButtonText: this.$t('commonVariables.complete'),
        cancelButtonText: this.$t('commonVariables.cancel'),
        type: 'warning',
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          const delRow = rows[index];
          // rows.splice(index, 1);
          if (delRow.id) {
            this.$emit('deleteData', delRow.id);
            this.$message({
              type: 'success',
              message: '已删除',
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除！',
          });
        });
    },
    indexMethod(index) {
      return index + 1 < 10 ? '0' + (index + 1) : index + 1;
    },
    //记录点击了哪一行
    rowClick(row) {
      this.$emit('update:saveKey', false);
      this.id = row.id;
    },
    /* 把选中的行的某些单元格替换为输入框 */
    choosedThisRow(idx, key, edit) {
      if (this.routeName !== '申办方维护') {
        return;
      }
      if (!this.updatePermission && !this.serviceTableData[idx].type) {
        return;
      }

      // 编辑情况下直接编辑
      if (this.serviceTableData[idx].id) {
        return this.id === this.serviceTableData[idx].id && edit;
      }
      // 新建项目情况下
      else {
        return edit && this.serviceTableData[idx];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';

.headerClass {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::v-deep .el-table__row {
  .el-table__cell {
    padding-top: 12px;
    padding-bottom: 12px;
    .hours-input {
      font-size: 14px;
      .el-input__inner {
        color: #515a6e;
        border: none;
        text-align: left;
        height: 29px;
        line-height: 29px;
        background-color: #fff;
      }
    }
    .start::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      width: 8px;
      height: 8px;
      background: #15ad31;
      border-radius: 50%;
    }
    .unStart::before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      width: 8px;
      height: 8px;
      background: #ff9810;
      border-radius: 50%;
    }
  }
}
.editServiceTable {
  height: 100%;
  padding: 12px 32px;
  padding-bottom: 0;
  background: #fff;
  margin: 0;
  overflow: hidden;
  border-radius: 2px;

  ::v-deep .el-table {
    color: #515a6e;
    .confirmLabel {
      background-color: #f8f8f8f9;
      color: #515a6e;
      font-weight: 600;
    }
    ::v-deep .el-table__row {
      .el-table__cell {
        padding-top: 12px;
        padding-bottom: 12px;
        .hours-input {
          font-size: 14px;
          .el-input__inner {
            color: #515a6e;
            border: none;
            text-align: left;
            height: 29px;
            line-height: 29px;
            background-color: #fff;
          }
        }
        .start::before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
          width: 8px;
          height: 8px;
          background: #15ad31;
          border-radius: 50%;
        }
        .unStart::before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
          width: 8px;
          height: 8px;
          background: #ff9810;
          border-radius: 50%;
        }
      }
    }

    ::v-deep .el-table__fixed-right {
      background-color: #fff;
      font-size: 14px;
      height: 100% !important; //设置高优先，以覆盖内联样式
    }
    ::v-deep .el-table__fixed {
      background-color: #fff;
      font-size: 14px;
      height: 100% !important; //设置高优先，以覆盖内联样式
    }
    ::v-deep .el-table__header {
      th:not(.is-hidden):nth-child(n-2) {
        color: #515a6e;
        background: #f8f8f9;
        .cell {
          padding-bottom: 1px;
        }
      }
      /*     th:not(.is-hidden):last-child::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      color: #DCDEE2;
      background: #DCDEE2;
      left: 0;
      bottom: 6px;
    } */
    }
    .circle {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 8px;
      margin-bottom: 0.2vh;
    }
    .defaultCursor {
      color: #c5c8ce;
      &:hover {
        cursor: not-allowed;
      }
    }

    .downloadUrl {
      cursor: pointer !important;
      color: #2d8cf0;
    }
    .operations {
      cursor: pointer;
      span {
        position: relative;
        display: inline-block;
        padding: 0 8.5px;
        // width: 126px;
        // height: 20px;
        color: #2d8cf0;
      }
      .pullMore {
        vertical-align: middle;
        display: inline-block;
        text-align: center;
        width: 16px;
        height: 16px;
        background: #e7f1ff;
        border-radius: 50%;
        .text {
          display: inline-block;
          padding: 0;
          transform: translateY(-30%);
        }
      }
      .pullMoreCheck {
        background: #e3eefd;
      }
      .selectData {
        border-radius: 4px;
        z-index: 9999999;
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        border: 1px solid #e4e7ed;
        position: absolute;
        width: 70px;
        right: 0;
        .selectUl {
          .selectLi {
            padding: 1px 0;
            font-size: 13px;
            border-radius: 4px;
            color: #2d8cf0;
            background: #fff;
            &:hover {
              background: #f8f8f9;
            }
          }
        }
        .selectUl:first-child {
          .selectLi {
            padding-top: 6px;
          }
        }
        .selectUl:last-child {
          .selectLi {
            padding-bottom: 6px;
          }
        }
      }
      .editRow::after {
        content: '';
        position: absolute;
        right: 0px;
        top: 4px;
        width: 1px;
        height: 14px;
        background: #e9e9e9;
      }
      /*     .deleteRow::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 4px;
      width: 1px;
      height: 14px;
      background: #e9e9e9;
    } */
    }
  }
  @import url('@styles/element-ui.scss');
}
::v-deep .el-input__inner {
  color: #515a6e;
  height: 28px;
  line-height: 28px;
}
@include tableClass;
::v-deep.show_empty .el-table__empty-text {
  display: initial;
}
</style>
