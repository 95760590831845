<template>
  <ag-grid-vue
    style="width: 100%; height: 570px; margin-top: -30px"
    class="ag-theme-alpine"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :defaultColDef="defaultColDef"
    :getContextMenuItems="getContextMenuItems"
    :tooltipShowDelay="1000"
    :tooltipHideDelay="3000"
  ></ag-grid-vue>
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';

export default {
  name: 'previewDialog',
  components: {
    AgGridVue,
  },
  props: {
    previewData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    rowData() {
      return JSON.parse(JSON.stringify(this.previewData));
    },
  },
  data() {
    const defaultColDef = {
      width: 105,
      editable: false,
      resizable: false, // 不可调整大小
      suppressMenu: true, // 隐藏列头菜单
      suppressMovable: true, // 锁定列，不允许拖动
      wrapText: true, // 溢出不隐藏，直接换行
      autoHeight: true, // 自动设置高度
    };
    const columnDefs = [
      // {
      //   field: 'sort',
      //   headerName: 'sort（D）',
      //   width: 130,
      //   pinned: 'left',
      //   lockPosition: 'left',
      //   rowDrag: true,
      //   editable: false,
      // },
      // {
      //   field: 'id',
      //   headerName: '服务项id',
      // },
      {
        headerName: '序号',
        valueGetter: 'node.rowIndex + 1',
        width: 85,
      },
      {
        field: 'projectCode',
        headerName: '合同编号',
        width: 146,
      },
      {
        field: 'projectSeq',
        headerName: '合同序号',
        // width: 146,
        width: 126,
      },
      {
        field: 'model',
        headerName: '模块',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['Project Management', 'DM', 'STAT', 'Data review meeting', 'MED', 'PK', 'PV', 'Conference travel expenses', 'Pass Through'],
        },
        width: 146,
      },
      {
        field: 'name',
        headerName: '合同服务项',
        // width: 204,
        width: 275,
      },
      {
        field: 'contractSize',
        headerName: '合同数量',
        cellDataType: 'number',
        width: 95,
      },
      {
        field: 'contractUnit',
        headerName: '合同单位',
        width: 146,
      },
      {
        field: 'unitPrice',
        headerName: '单价',
        cellDataType: 'number',
      },
      {
        field: 'amount',
        headerName: '总价',
        cellDataType: 'number',
      },
      {
        field: 'remark',
        headerName: '备注',
        width: 203,
      },
      {
        field: 'currency',
        headerName: '货币',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['USD', 'RMB'],
        },
        width: 85,
      },
      {
        field: 'discount',
        headerName: '折扣',
        cellDataType: 'number',
      },
      {
        field: 'isDeleted',
        hide: true,
      },
    ];
    return {
      columnDefs: columnDefs,
      defaultColDef: defaultColDef,
    };
  },
  methods: {
    getContextMenuItems() {
      return []; // 返回一个空数组，禁用右键菜单
    },
  },
};
</script>

<style lang="scss" scoped></style>
