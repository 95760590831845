import { BaseService } from '@/api/request';
const api = {};
// NaN undefined null过滤处理
// 注意，尽量不要过滤空字符串
const filterNull = json => {
  let newJson = {};
  let reg = RegExp(/NaN|undefined|null/);
  for (let li in json) {
    if (json[li] && !reg.test(json[li])) {
      newJson[li] = json[li];
    }
  }
  return newJson;
};

api.getContractList = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/contracts', filterNull(params));
};
api.getBdOption = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/resource/customers/bds', filterNull(params));
};
api.getuserOption = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/contracts/users', filterNull(params));
};
api.getCurrentContract = function (id) {
  return BaseService.getRequest('api/pmf/v1/contracts/' + id);
};
api.createContract = function (params = {}) {
  return BaseService.postRequest('api/pmf/v1/contracts', filterNull(params));
};

api.updateContract = function (params = {}, id) {
  return BaseService.putRequest('api/pmf/v1/contracts/' + id, filterNull(params));
};
api.delContract = function (id) {
  return BaseService.delRequest('api/pmf/v1/contracts/' + id);
};
api.uploadContract = function (params = {}) {
  return BaseService.getRequest('api/pmf/v1/oss/sts', params);
};
export default api;
