<template>
  <ag-grid-vue
    style="width: 100%; height: 570px; margin-top: -30px"
    class="ag-theme-alpine"
    :columnDefs="columnDefs"
    :rowData="rowData"
    @grid-ready="onGridReady"
    :enableRangeSelection="true"
    :rowSelection="'multiple'"
    :undoRedoCellEditing="true"
    :rowDragManaged="true"
    :animateRows="true"
    :enableFillHandle="true"
    :defaultColDef="defaultColDef"
    @cellValueChanged="onCellValueChanged"
    @rowDragEnter="onRowDragEnter"
    @rowDragEnd="onRowDragEnd"
    :suppressMoveWhenRowDragging="false"
    :rowClassRules="rowClassRules"
    :getContextMenuItems="getContextMenuItems"
    :suppressLastEmptyLineOnPaste="true"
    :tooltipShowDelay="1000"
    :tooltipHideDelay="3000"
  ></ag-grid-vue>
  <!-- @grid-ready="onGridReady"初始化 -->
  <!-- :enableRangeSelection="true"启用范围选择 -->
  <!-- :rowSelection="'multiple'"多行选择 -->
  <!-- :undoRedoCellEditing="true"启用撤销Ctrl+Z -->
  <!-- :rowDragManaged="true"启用行拖拽 -->
  <!-- :animateRows="true"启用行拖拽动画 -->
  <!-- :enableFillHandle="true"启用填充功能 -->
  <!-- :defaultColDef="defaultColDef"默认列设置 -->
  <!-- @cellValueChanged="onCellValueChanged"单元格值改变事件 -->
  <!-- @rowDragEnter="onRowDragEnter"行拖拽开始事件 -->
  <!-- @rowDragEnd="onRowDragEnd"行拖拽完成事件 -->
  <!-- :suppressMoveWhenRowDragging="true"禁止行拖拽时移动行 -->
  <!-- :rowClassRules="rowClassRules"行样式规则 -->
  <!-- :getContextMenuItems="getContextMenuItems"右键菜单 -->
  <!-- :suppressLastEmptyLineOnPaste="true"解决excel错误问题 -->
  <!-- :tooltipShowDelay="1000"鼠标悬浮1s后提示信息 -->
  <!-- :tooltipHideDelay="3000"鼠标悬浮3s后关闭提示信息 -->
</template>

<script>
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import operateCellRenderer from './operateCellRenderer.vue';
import projectManagementApi from '@/api/modules/projectManagement.api.js';
import headerComponent from './headerComponent.vue';

export default {
  name: 'batchModifiedDialog',
  components: {
    AgGridVue,
    operateCellRenderer,
    headerComponent,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    tableData: function (newVal) {
      this.rowData = JSON.parse(JSON.stringify(newVal));
      this.initData();
    },
    rowData: function (newVal) {
      this.previewData = JSON.parse(JSON.stringify(newVal));
    },
  },
  // computed: {
  //   rowData() {
  //     return JSON.parse(JSON.stringify(this.tableData));
  //   },
  // },
  data() {
    const defaultColDef = {
      width: 105,
      resizable: false, // 不可调整大小
      suppressMenu: true, // 隐藏列头菜单
      suppressMovable: true, // 锁定列，不允许拖动
      editable: params => !params.data.isDeleted, // 不允许编辑已删除的行
      wrapText: true, // 溢出不隐藏，直接换行
      autoHeight: true, // 自动设置高度
    };
    const columnDefs = [
      // {
      //   field: 'sort',
      //   headerName: 'sort（D）',
      //   width: 130,
      //   pinned: 'left',
      //   lockPosition: 'left',
      //   rowDrag: true,
      //   editable: false,
      // },
      // {
      //   field: 'id',
      //   headerName: '服务项id',
      //   // pinned: 'left',
      //   // lockPosition: 'left',
      //   rowDrag: true,
      //   editable: false,
      // },
      {
        headerName: '序号',
        valueGetter: 'node.rowIndex + 1',
        rowDrag: true,
        editable: false,
        suppressFillHandle: true, // 关闭填充功能
        width: 90,
      },
      {
        field: 'projectCode',
        headerName: '合同编号',
        // tooltipField: 'projectCode',
        width: 130,
      },
      {
        field: 'projectSeq',
        headerName: '合同序号',
        // tooltipField: 'projectSeq',
        // width: 130,
        width: 120,
      },
      {
        field: 'model',
        headerName: '模块',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['Project Management', 'DM', 'STAT', 'Data review meeting', 'MED', 'PK', 'PV', 'Conference travel expenses', 'Pass Through'],
        },
        // tooltipField: 'model',
        width: 130,
        valueGetter: params => {
          let isExist = params.colDef.cellEditorParams.values.findIndex(item => item === params.data.model);
          return isExist === -1 ? null : params.data.model;
        },
        headerComponent: 'headerComponent',
        headerComponentParams: {
          title: '模块',
          tooltip: '模块只能从项目中现有的模块选择',
        },
      },
      {
        field: 'name',
        headerName: '合同服务项',
        // tooltipField: 'name',
        // width: 183,
        width: 234,
      },
      {
        field: 'contractSize',
        headerName: '合同数量',
        cellDataType: 'number',
        width: 95,
      },
      {
        field: 'contractUnit',
        headerName: '合同单位',
        // tooltipField: 'contractUnit',
        width: 130,
      },
      {
        field: 'unitPrice',
        headerName: '单价',
        cellDataType: 'number',
      },
      {
        field: 'amount',
        headerName: '总价',
        cellDataType: 'number',
      },
      {
        field: 'remark',
        headerName: '备注',
        // tooltipField: 'remark',
        width: 183,
      },
      {
        field: 'currency',
        headerName: '货币',
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: ['USD', 'RMB'],
        },
        valueGetter: params => {
          let isExist = params.colDef.cellEditorParams.values.findIndex(item => item === params.data.currency);
          return isExist === -1 ? null : params.data.currency;
        },
        headerComponent: 'headerComponent',
        headerComponentParams: {
          title: '货币',
          tooltip: '货币只能从项目中现有的货币选择',
        },
        width: 90,
      },
      {
        field: 'discount',
        headerName: '折扣',
        cellDataType: 'number',
      },
      {
        field: 'isDeleted',
        hide: true,
      },
      {
        field: '操作',
        // pinned: 'right',
        // lockPosition: 'right',
        cellRenderer: 'operateCellRenderer',
        editable: false,
        suppressFillHandle: true,
        wrapText: false,
        autoHeight: false,
      },
    ];
    return {
      previewData: [], // 暂存修改后的数据(预览)
      columnDefs: columnDefs,
      rowData: JSON.parse(JSON.stringify(this.tableData)),
      defaultColDef: defaultColDef,
      drag: {
        enter: '',
        end: '',
      },
      rowClassRules: null,
    };
  },
  created() {
    this.rowClassRules = {
      'row-deleted': params => {
        return params.data.isDeleted;
      },
    };
  },
  beforeMount() {
    this.initData();
    this.previewData = JSON.parse(JSON.stringify(this.rowData));
    console.log(this.rowData);
    console.log(this.previewData);
  },
  mounted() {
    this.addItem();
    this.resetData();
    this.deleteItem();
    this.getPreviewData();
  },
  methods: {
    onGridReady(params) {
      this.api = params.api;
      this.columnApi = params.columnApi;
    },
    getContextMenuItems() {
      return []; // 返回一个空数组，禁用右键菜单
    },
    onCellValueChanged(event) {
      // 判断模块是否合法
      if (event.colDef.field === 'model') {
        let isExist = event.colDef.cellEditorParams?.values.findIndex(item => item === event.newValue);
        if (isExist === -1) {
          return;
        }
      }
      // 判断货币是否合法
      if (event.colDef.field === 'currency') {
        let isExist = event.colDef.cellEditorParams?.values.findIndex(item => item === event.newValue);
        if (isExist === -1) {
          return;
        }
      }
      this.previewData.splice(event.rowIndex, 1, event.data);
    },
    onRowDragEnter(event) {
      this.drag.enter = this.drag.enter === '' ? event.overIndex : this.drag.enter;
    },
    onRowDragEnd(event) {
      this.drag.end = event.overIndex;
      // 拖动到表格内部空位置时触发该函数，解决向下拖动至最后一行后面区域时产生的bug
      // 向上拖动暂时不会产生该bug，因为第一行与表格顶部的距离为0，不会导致end值为-1
      if (this.drag.end === -1) {
        this.drag.end = this.previewData.length - 1;
      }
      if (this.drag.enter === this.drag.end) {
        this.resetDrag();
        return;
      }
      let changeItem = this.previewData.splice(this.drag.enter, 1);
      let prevSort = this.drag.end <= 0 ? -999 : this.previewData[this.drag.end - 1].sort;
      let nextSort = this.drag.end === this.previewData.length ? 999 : this.previewData[this.drag.end].sort;
      changeItem[0].sort = (prevSort + nextSort) / 2;
      this.previewData.splice(this.drag.end, 0, changeItem[0]);
      this.resetDrag();
      console.log(this.previewData);
    },
    resetDrag() {
      this.drag = {
        enter: '',
        end: '',
      };
    },
    createNewItem(sort) {
      return {
        sort,
        id: null,
        projectCode: '',
        projectSeq: '',
        model: '',
        name: '',
        contractSize: null,
        contractUnit: '',
        unitPrice: null,
        amount: null,
        currency: '',
        discount: null,
        remark: '',
      };
    },
    addItem() {
      this.$bus.$on('addItem', (index, up, down) => {
        if (index === -1) {
          this.$message.error('添加服务项出错');
          return;
        }
        let addsUp = [];
        let addsDown = [];
        let prevSort = index === 0 ? -999 : this.previewData[index - 1].sort;
        for (let i = 0; i < up; i++) {
          let nextSort = this.previewData[index + i].sort;
          let sort = (prevSort + nextSort) / 2;
          prevSort = sort;
          addsUp.push(this.createNewItem(sort));
          this.previewData.splice(index + i, 0, this.createNewItem(sort));
        }
        this.api.applyTransaction({ add: addsUp, addIndex: index });
        prevSort = this.previewData[index + up].sort;
        for (let i = 0; i < down; i++) {
          let nextSort = index + up + i === this.previewData.length - 1 ? 999 : this.previewData[index + up + i + 1].sort;
          let sort = (prevSort + nextSort) / 2;
          prevSort = sort;
          addsDown.push(this.createNewItem(sort));
          this.previewData.splice(index + 1 + up + i, 0, this.createNewItem(sort));
        }
        this.api.applyTransaction({ add: addsDown, addIndex: index + 1 + up });
        console.log(this.previewData);
      });
    },
    resetData() {
      this.$bus.$on('resetData', () => {
        this.rowData = JSON.parse(JSON.stringify(this.tableData));
        this.initData();
      });
    },
    deleteItem() {
      this.$bus.$on('deleteItem', async node => {
        if (node.data.id) {
          const res = await projectManagementApi.verifyServiceItemTimeFilling(node.data.id);
          if (res[0].exists) {
            this.$alert('该服务项已有工时，无法删除。', '提示', {
              confirmButtonText: '确定',
              type: 'error',
            });
            return;
          }
          if (res[0].scheduleExists) {
            this.$alert('该服务项已有进度，无法删除。', '提示', {
              confirmButtonText: '确定',
              type: 'error',
            });
            return;
          }
        }
        this.$message.success('删除服务项成功');
        node.setDataValue('isDeleted', true);
      });
    },
    getPreviewData() {
      this.$bus.$on('getPreviewData', callback => {
        callback(this.previewData);
      });
    },
    initData() {
      this.rowData.forEach((item, index) => {
        item.sort = index;
      });
    },
  },
  beforeDestroy() {
    this.api = null;
    this.columnApi = null;
    this.$bus.$off('addItem');
    this.$bus.$off('resetData');
    this.$bus.$off('deleteItem');
    this.$bus.$off('getPreviewData');
  },
};
</script>

<style lang="scss">
.ag-theme-alpine {
  // 删除行样式
  .row-deleted {
    background-color: #ffff99 !important;
  }
  // 文字的样式
  .ag-header-cell-text,
  .ag-cell-value {
    color: #515a6e;
  }
  // 每行的样式
  .ag-row-even,
  .ag-row-odd {
    border-bottom: 1px solid #ebeef5;
    background-color: #fff;
  }
  // // 最后一行的样式（开启后新增行的最后一行会丢失下边框）
  // .ag-row:last-child {
  //   border-bottom: none;
  // }
  // 表格的样式
  .ag-root-wrapper {
    border: 1px solid #ebeef5;
    border-radius: 4px 4px 0px 0px;
    // 表头的样式
    .ag-header {
      border-bottom: 1px solid #ebeef5;
      background-color: #f8f8f8;
    }
    // 表头中每列的样式
    .ag-header-cell {
      border-right: 1px solid #ebeef5;
    }
    // // 表头中最后一列的样式
    // .ag-header-cell:last-child {
    //   border-right: none;
    // }
  }
  // 填充按钮的样式
  .ag-fill-handle {
    height: 6px !important;
    width: 6px !important;
  }
  // 单选框的样式
  .ag-picker-field-wrapper {
    height: 70% !important;
    border: 1px solid #dcdfe6;
    color: #606266;
    .ag-picker-field-icon {
      color: #d9d4e0;
    }
  }
  // 输入框的样式
  .ag-text-field-input,
  .ag-number-field-input {
    height: 70% !important;
    box-shadow: none;
    border: 1px solid #dcdfe6 !important;
    text-align: center;
    border-radius: 5px !important;
    color: #606266;
  }
  // 选择框子项的样式
  .ag-select-list-item {
    color: #606266;
  }
  // 输入框被激活时的样式
  .ag-cell-inline-editing {
    box-shadow: none;
    background-color: rgb(231, 241, 252); // #ecf5ff
  }
  .ag-cell-editor {
    padding: 0 10px;
  }
  // 表格中的文字垂直居中
  // 表格中的按钮垂直居中
  .ag-cell-wrap-text,
  .ag-cell-range-right,
  .ag-cell-range-single-cell {
    display: flex;
    align-items: center;
  }
  // 设置行高
  .ag-cell-wrap-text {
    line-height: min(calc(var(--ag-internal-calculated-line-height) - 22px), calc(var(--ag-internal-padded-row-height) - 22px));
  }
  // 单元格内边距
  .ag-cell-value {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  // 鼠标悬浮行的颜色
  --ag-row-hover-color: rgba(236, 240, 246, 0.5); // #f5f7fa
  // 当前选中行的高亮颜色
  --ag-selected-row-background-color: rgba(218, 235, 255, 0.5); // #ecf5ff
  // 选中单元格的边框颜色（填充按钮的颜色）
  --ag-range-selection-border-color: #4b8cff;
  // 选中单元格的背景颜色
  --ag-range-selection-background-color: #e5f3ff;
  // 复制粘贴时的短暂背景色
  --ag-range-selection-highlight-color: #cee7fd;
  // 输入框、单选框的边框颜色
  --ag-input-focus-border-color: #ebeef5;
  // 单元格边框
  --ag-cell-horizontal-border: 1px solid #ebeef5;
  // 关闭输入框被激活时的阴影
  --ag-input-focus-box-shadow: none;
}
</style>
