<template>
  <div>
    <el-popover placement="top" width="274" trigger="click" v-model="popoverVisible" style="margin-right: 8px">
      <el-input-number v-model="operate.up" class="inputUp" :min="0"></el-input-number>
      <el-input-number v-model="operate.down" class="inputDown" :min="0"></el-input-number>
      <div class="footer" style="margin-bottom: 8px; margin-right: 10px">
        <el-button size="small" @click="cancelAddItem" style="margin-right: 2px">取 消</el-button>
        <el-button size="small" @click="addItem" type="primary" :disabled="!canAddItem">确 定</el-button>
      </div>
      <el-button size="small" slot="reference" type="text">新增</el-button>
    </el-popover>
    <span style="color: #eeeeee">|</span>
    <el-popconfirm title="确定删除该服务项吗？" style="margin-left: 8px" @cancel="cancelDelete" @confirm="deleteItem">
      <el-button size="small" slot="reference" :disabled="deleteDisabled" type="text">删除</el-button>
    </el-popconfirm>
  </div>
</template>
<script>
export default {
  name: 'operateCellRenderer',
  data() {
    return {
      operate: {
        up: 0,
        down: 0,
      },
      deleteDisabled: false,
      popoverVisible: false,
    };
  },
  computed: {
    canAddItem() {
      return this.operate.up > 0 || this.operate.down > 0;
    },
  },
  methods: {
    addItem() {
      this.$bus.$emit('addItem', this.params.node.rowIndex, this.operate.up, this.operate.down);
      this.operate.up = 0;
      this.operate.down = 0;
      this.popoverVisible = false;
    },
    deleteItem() {
      this.$bus.$emit('deleteItem', this.params.node);
      this.deleteDisabled = true;
    },
    cancelAddItem() {
      this.operate.up = 0;
      this.operate.down = 0;
      this.popoverVisible = false;
    },
    cancelDelete() {},
  },
};
</script>
<style lang="scss" scoped>
.el-button {
  padding: 0 !important;
}
.inputUp {
  width: 57.5%;
  display: inline-block;
  margin-left: 30%;
  margin-top: 12px;
}
.inputUp::before {
  content: '向上增加';
  position: absolute;
  left: -46%;
}
.inputUp::after {
  content: '行';
  position: absolute;
  right: -15%;
  top: 0;
}
.inputDown {
  width: 57.5%;
  display: inline-block;
  margin-left: 30%;
  margin-top: 24px;
  margin-bottom: 20px;
}
.inputDown::before {
  content: '向下增加';
  position: absolute;
  left: -46%;
}
.inputDown::after {
  content: '行';
  position: absolute;
  right: -15%;
  top: 0;
}
.footer {
  text-align: right;
  margin-top: 8px;
  .el-button {
    padding: 8px 15px !important;
  }
}
// 解决测试环境样式错误问题
::v-deep .el-input__inner {
  height: 40px !important;
}
</style>
