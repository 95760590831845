<template>
  <div class="project-management__page">
    <div class="tree-sub" ref="tree-sub">
      <div class="tree-menu" :style="treeHeight">
        <TreeMenu
          :mutipleCheck="true"
          :treeMenuName="$t('workRecord.project')"
          :searchName="$t('workRecord.findProject')"
          operationShowAddBtn
          :operationCreate="operationCreate"
          :operationExport="operationExport"
          :projectArr="projectArr"
          isTreeStretch
          :projectStatus="projectStatus"
          :choosedContractItem="choosedContractItem"
          :allUser="allUser"
          :boxLoading="boxLoading"
          @addProject="addProject"
          @getChoosedContractItem="getChoosedContractItem"
          @transfromStatus="transfromStatus"
          operationShowStatusSelect
          @searchKeyword="searchKeyword"
          routeTit="运营管理"
          remoteSearch
        />
      </div>
    </div>
    <div class="app-box" :style="{ width: tableWidth + 'px !important' }">
      <div class="top-box" ref="headerBtns">
        <div class="header">
          <div class="top-header-tit">
            <p class="tit-container">{{ $t('projectManagement.projectHoursTable') }}</p>
            <div class="box">
              <div class="circle"></div>
              <p class="currentRord">{{ choosedContractItem.name }}</p>
            </div>
            <div class="contractDialog">
              <GLIcon icon="icon-hetongmingcheng" :extraStyle="true" class="exportIcon" />
              <p @click="addContractName" class="checkContractName">合同名称</p>
            </div>
          </div>
          <div class="right-container">
            <div @click="toggleExpand" class="tit-container expandControl">
              <span>{{ expanded ? $t('commonVariables.fold') : $t('commonVariables.unfold') }}</span
              ><i :class="expanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </div>
            <el-button @click="operateDelete" size="medium" v-if="operationDelete">{{ $t('projectManagement.deleteItem') }}</el-button>
            <el-button @click="operateProject" size="medium" class="optBtn" v-if="ruleForm.status && OperationRestart">{{
              $t('projectManagement.replacementItem')
            }}</el-button>
            <el-button @click="operateProject" size="medium" class="optBtn" v-else-if="!ruleForm.status && OperationEnd">{{
              $t('projectManagement.endItem')
            }}</el-button>
            <div v-if="projectArr.length && operationUpdate" class="header-btns">
              <el-button @click="cancelEdit" size="medium">{{ $t('commonVariables.reset') }}</el-button>
              <el-button @click="saveEdit" class="savebtn" size="medium" type="primary">{{ $t('projectManagement.save') }}</el-button>
            </div>
          </div>
        </div>
        <!-- top -->
        <TransitionCollapse @expandOver="expandOver" @retractOver="expandOver" v-show="expanded" :expandHeight="expandHeight">
          <el-form class="edit_from" :model="ruleForm" ref="ruleForm" :rules="rules" label-position="top" label-width="180px">
            <el-form-item prop="code" :label="$t('projectManagement.projectNo')">
              <el-input placeholder="请输入项目编号" size="mini" v-model="ruleForm.code" clearable class="projInput"> </el-input>
            </el-form-item>

            <el-form-item prop="projectName" :label="$t('projectManagement.projectName')">
              <el-input v-model="ruleForm.projectName" clearable placeholder="请输入" :disabled="disabledUpdate" class="projInput"></el-input>
            </el-form-item>
            <el-form-item prop="director" :label="$t('projectManagement.projectDirector')">
              <el-select
                @change="changePdDate"
                size="mini"
                :value="ruleForm.director"
                filterable
                clearable
                :disabled="disabledUpdate"
                class="projInput"
              >
                <el-option v-for="item in allUser" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('projectManagement.projectAD')">
              <el-select size="mini" v-model="ruleForm.ad" filterable clearable :disabled="disabledUpdate" class="projInput">
                <el-option v-for="item in allUser" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="directorSelected" :label="$t('projectManagement.projectBO')">
              <el-select
                size="mini"
                @change="changeBoDate"
                :value="ruleForm.directorSelected"
                filterable
                clearable
                :disabled="disabledUpdate"
                class="projInput"
              >
                <el-option v-for="item in allUser" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="chiefPMSelected" :label="$t('projectManagement.projectLEAD')">
              <el-select size="mini" v-model="ruleForm.chiefPMSelected" filterable clearable :disabled="disabledUpdate" class="projInput">
                <el-option v-for="item in allUser" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('projectManagement.Member')">
              <memberSelect
                class="projInput"
                :projectUpdate="projectUpdate"
                :disabledUpdate="disabledUpdate"
                :memberSelected="memberSelected"
                :allUser="allUser"
                @changeMemberSelected="changeMemberSelected"
              />
            </el-form-item>

            <el-form-item :label="$t('projectManagement.TimeFrame')">
              <el-date-picker
                v-model="dateVal"
                class="el-date-picker projInput"
                type="daterange"
                range-separator="至"
                :unlink-panels="true"
                :start-placeholder="startPlaceholder"
                :end-placeholder="endPlaceholder"
                :disabled="disabledUpdate"
              ></el-date-picker>
            </el-form-item>

            <el-form-item prop="category" :label="$t('projectManagement.executiveDepartment')">
              <el-select size="mini" v-model="ruleForm.category" filterable clearable class="projInput">
                <el-option v-for="item in categoryType" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="sow" :label="$t('projectManagement.sow')">
              <el-input placeholder="请输入" size="mini" v-model="ruleForm.sow" clearable class="projInput"> </el-input>
            </el-form-item>
            <el-form-item prop="belong" :label="$t('projectManagement.projectBelong')">
              <el-select size="mini" v-model="ruleForm.belong" filterable clearable :disabled="disabledUpdate" class="projInput">
                <el-option v-for="item in belongs" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="type" :label="$t('projectManagement.projectType')">
              <el-select size="mini" v-model="ruleForm.type" filterable clearable :disabled="disabledUpdate" class="projInput">
                <el-option v-for="item in projectType" :key="item" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </TransitionCollapse>
      </div>
      <div class="heat-shield"></div>
      <div class="bottom-box" ref="bottom-box">
        <div class="header" ref="header">
          <span class="operatorHeaderTit">{{ $t('projectManagement.editServiceItem') }}</span>
          <div v-if="projectArr.length && operationUpdate" class="header-btns">
            <el-button @click="addServiceItem" class="createNewbtn" size="medium" :disabled="!(!tableLoading && !boxLoading)">
              {{ $t('projectManagement.createNewServiceItem') }}
            </el-button>
            <el-button @click="leadingInVisible = true" class="leadingInbtn" size="medium" :disabled="!(!tableLoading && !boxLoading)">
              {{ $t('publicManagement.leadingInTaskItem') }}
            </el-button>
            <el-button
              v-if="operationUpdate"
              @click="openBatchModifiedDialog"
              class="leadingInbtn"
              size="medium"
              :disabled="!(!tableLoading && !boxLoading)"
            >
              {{ $t('operationManagement.batchModification') }}
            </el-button>
            <el-button
              v-if="operationUpdate"
              @click="saveAllServiceItems"
              class="savebtn"
              size="medium"
              type="primary"
              :disabled="!(!tableLoading && !boxLoading)"
            >
              {{ $t('projectManagement.save') }}
            </el-button>
          </div>
        </div>

        <EditServiceItemTable
          :matchArr="matchArr"
          :tableHeight="tableHeight"
          :tableLoading.sync="tableLoading"
          :originTableData="tableData"
          :serviceTableData="serviceTableData"
          @editShowDialog="editShowDialog"
          :tableOperations="tableOperations"
          @deleteRow="deleteRow"
          @setJustSettlement="setJustSettlement"
        />
      </div>
      <LeadIn
        routeName="运营管理"
        title="批量导入服务项"
        :leadingInVisible.sync="leadingInVisible"
        :confirmId="choosedContractItem.id"
        @refreshList="refreshList"
      />
      <SimpleDialog
        class="serviceEdit"
        :title="dialogTitle"
        :visible.sync="dialogFormVisible"
        width="565px"
        showConfirm
        showCancel
        @onCancel="serviceCancel"
        @onSubmit="onNoticeDialogSubmit"
      >
        <el-form :model="serviceForm" ref="serviceForm" :rules="serviceRules" class="serviceForm" label-position="right" label-width="100px">
          <el-form-item prop="name" :label="$t('projectManagement.serveName')">
            <el-input placeholder="请输入服务项名称" size="mini" v-model="serviceForm.name" clearable> </el-input>
          </el-form-item>
          <el-form-item prop="parentServiceId" label="上级服务项">
            <el-select v-model="parentServiceId" filterable clearable style="width: 100%" disabled>
              <el-option v-for="item in serviceOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="$t('projectManagement.projectCode')">
            <el-input size="mini" v-model="serviceForm.projectCode" clearable :class="{ 'is-edit-disabled': serviceDialogStatus === 'edit' }">
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.projectSeq')">
            <el-input size="mini" v-model="serviceForm.projectSeq" clearable :class="{ 'is-edit-disabled': serviceDialogStatus === 'edit' }">
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.contractSize')" class="specialItem">
            <el-input
              placeholder="请输入合同数量"
              size="mini"
              v-model="serviceForm.contractSize"
              clearable
              :disabled="serviceDialogStatus === 'edit' && serviceForm.disabledEdit"
            >
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.contractUnit')" class="specialItem">
            <el-input
              placeholder="请输入合同单位"
              size="mini"
              v-model="serviceForm.contractUnit"
              clearable
              :disabled="serviceDialogStatus === 'edit' && serviceForm.disabledEdit"
            >
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.unitPrice')" class="specialItem" prop="unitPrice">
            <el-input-number :controls="false" size="mini" v-model="serviceForm.unitPrice" clearable style="width: 148px"> </el-input-number>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.amount')" class="specialItem" prop="amount">
            <el-input-number :controls="false" size="mini" v-model="serviceForm.amount" clearable style="width: 148px"> </el-input-number>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.discount')" class="specialItem" prop="discount">
            <el-input-number :controls="false" size="mini" v-model="serviceForm.discount" clearable style="width: 148px"> </el-input-number>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.currency')" class="specialItem">
            <el-select v-model="serviceForm.currency" filterable clearable style="width: 100%">
              <el-option v-for="item in currencyOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.module')">
            <el-select v-model="serviceForm.model" filterable clearable style="width: 100%">
              <el-option v-for="item in moduleOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.remark')" class="remarkItem">
            <el-input type="textarea" :rows="3" placeholder="请输入备注" size="mini" v-model="serviceForm.remark" clearable> </el-input>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.initialSize')" class="specialItem" prop="initialSize">
            <el-input-number :controls="false" size="mini" v-model="serviceForm.initialSize" clearable style="width: 148px"> </el-input-number>
          </el-form-item>
          <el-form-item :label="$t('projectManagement.initialAmount')" class="specialItem" prop="initialAmount">
            <el-input-number :controls="false" size="mini" v-model="serviceForm.initialAmount" clearable style="width: 148px"> </el-input-number>
          </el-form-item>
          <el-form-item label-width="100px" label="项目编辑" class="remarkItem">
            <el-radio-group size="mini" v-model="serviceForm.disabledEdit">
              <el-radio-button :label="true">禁止</el-radio-button>
              <el-radio-button :label="false">开放</el-radio-button>
            </el-radio-group>
            <span class="remarkTips">禁止则项目组无法修改此服务项信息</span>
          </el-form-item>
        </el-form>
      </SimpleDialog>

      <SimpleDialog
        class="checkContractDialog"
        title="编辑合同名称"
        :visible.sync="dialogContractVisible"
        width="814px"
        showConfirm
        showCancel
        @onCancel="contractDialogCancel"
        @onSubmit="contractDialogSubmit"
      >
        <EditRowContainer
          isDeleted
          @emitContractData="emitContractData"
          class="editRowContainer"
          leftHeader="编辑合同名称"
          rightHeader="添加"
          :fillDataArr="fillDataArr"
          :contractNames="contractNames"
          :dialogContractVisible="dialogContractVisible"
        />
      </SimpleDialog>

      <SimpleDialog
        class="selectDateDialog"
        :visible.sync="dialogDateVisible"
        width="288px"
        showConfirm
        showCancel
        :title="ruleForm.status ? '请选择重新开始时间' : '请选择结束时间'"
        @onCancel="dateDialogCancel"
        @onSubmit="dateDialogSubmit"
      >
        <div class="block">
          <el-date-picker value-format="yyyy-MM-dd" v-model="choosedDate" type="date" placeholder="选择日期"> </el-date-picker>
        </div>
      </SimpleDialog>

      <SimpleDialog
        class="selectDateDialog"
        :visible.sync="dialogChangeDateVisible"
        width="488px"
        showConfirm
        showCancel
        :title="changeTitle"
        @onCancel="changeDateDialogCancel"
        @onSubmit="changeDateDialogSubmit"
      >
        <div class="dateRadioGroup">
          <el-radio class="sub" @input="updatePickDate('all')" v-model="updateDateType" :label="false">全部更新</el-radio>
          <el-radio class="sub" @input="updatePickDate('appoint')" v-model="updateDateType" :label="true">从指定日期开始</el-radio>
          <el-date-picker
            v-if="updateDateType"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            v-model="updateDate"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
      </SimpleDialog>

      <!-- 批量修改对话框 -->
      <el-dialog
        class="batchModifiedDialog"
        :visible.sync="dialogBatchModifiedVisible"
        width="95%"
        @close="batchModifiedDialogCancel"
        :destroy-on-close="false"
        :close-on-click-modal="false"
        :show-close="false"
      >
        <batchModifiedDialog :tableData="batchModifyData" v-if="batchModifyDialogRender" />
        <div slot="footer" class="dialog-footer" style="margin-top: -15px">
          <el-button style="height: 32px" @click="openPreviewResultDialog" size="small">
            {{ dialogPreviewResultVisible ? '取 消 预 览' : '预 览' }}
          </el-button>
          <el-button style="height: 32px; margin-left: 16px; margin-right: 6px" @click="batchModifiedDialogCancel" size="small">取 消</el-button>
          <el-button style="height: 32px; margin-right: 7px" type="primary" @click="batchModifiedDialogSubmit" size="small" :loading="updateLoading">
            更 新
          </el-button>
        </div>
      </el-dialog>
      <!-- 预览结果对话框 -->
      <el-dialog
        class="batchModifiedDialog"
        :visible.sync="dialogPreviewResultVisible"
        width="95%"
        @close="previewResultDialogCancel"
        :close-on-click-modal="false"
        :show-close="false"
      >
        <previewDialog :previewData="previewData" v-if="previewDialogRender" />
        <div slot="footer" class="dialog-footer" style="margin-top: -15px">
          <el-button style="height: 32px; margin-right: 7px" @click="previewResultDialogCancel" size="small">关 闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import TreeMenu from '@/components/TreeMenu/TreeMenu.vue';
import EditRowContainer from '@/components/EditRowContainer/index.vue';
import memberSelect from '@/components/memberSelect/index.vue';
import TransitionCollapse from '@/components/common/TransitionCollapse';
import LeadIn from '@/components/LeadIn/index.vue';
import _ from 'lodash';
import { dayFormat } from '@/utils/time-tools';

import EditServiceItemTable from './components/EditServiceItemTable.vue';
import batchModifiedDialog from './components/batchModifiedDialog.vue';
import previewDialog from './components/previewDialog.vue';

import projectManagementApi from '@/api/modules/projectManagement.api.js';
import operationManagementApi from '@/api/modules/operationManagement.api.js';
import scheduleManagementApi from '@/api/modules/scheduleManagement.api.js';

import { mixinsWorkRecord } from '@/mixins/treeMenu';
import { mixinsProjectStatement } from '@/mixins/authGroup';
export default {
  name: 'OperationManagement',
  mixins: [mixinsWorkRecord, mixinsProjectStatement],
  components: { TreeMenu, memberSelect, EditServiceItemTable, batchModifiedDialog, LeadIn, TransitionCollapse, EditRowContainer, previewDialog },

  data() {
    const validateProjectName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请填写项目名称'));
      } else if (this.projectNameError) {
        callback(new Error('名称已被占用'));
      } else {
        callback();
      }
    };
    const validateServiceName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请填写服务项名称'));
      } else if (this.serviceNameValidateError) {
        callback(new Error('该项目的服务项名称已被占用'));
      } else {
        callback();
      }
    };
    // const projectTypeIsRequired = (rule, value, callback) => {
    //   if (!value && this.isRequired) {
    //     callback(new Error('请选择项目类型'));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      projectArrKeyword: '',
      appointType: '',
      appointValue: '',
      pickerOptions: {
        disabledDate: this.disabledDate,
      },
      updateDateType: false,
      boxLoading: false,
      updateDate: null,
      choosedDate: '',
      projectStatus: 0,
      // label,type,valueType,position,optionGroup
      fillDataArr: [
        { label: 'contractCode', type: 'select', position: 0, optionGroup: [] },
        { label: 'contractName', type: 'input', position: 1 },
      ],
      contractNames: [],
      submitContractNames: [],
      lastChoosedItem: null,
      matchArr: [],
      workDialogTitle: '',
      projectType: [],
      workRules: {
        id: [{ required: true, message: '请选择服务项', trigger: 'change' }],
      },
      categoryType: ['DM', 'SP', 'MED', 'PK', 'CP', 'SDTM', '研发', 'ST', '建库', 'IT', '其他'],
      tableOperations: [
        {
          label: 'projectCode',
          value: 'projectCode',
          width: 30,
        },
        {
          label: 'projectSeq',
          value: 'projectSeq',
          width: 30,
        },
        {
          label: 'OperationalServiceItem',
          value: 'name',
          showTips: true,
          style: 'margin-left: -4px; margin-right: 8px',
          width: 60,
          desc: '右上角有橙色角标服务项为据实结算',
        },
        {
          label: 'contractSize',
          value: 'contractSize',
          showTips: true,
          style: 'margin-left: -4px; margin-right: 8px',
          width: 20,
        },
        {
          label: 'contractUnit',
          value: 'contractUnit',
          showTips: true,
          style: 'margin-left: -4px; margin-right: 8px',
          width: 40,
        },
      ],
      // 抽屉
      tableWidth: 0,
      serviceNameValidateError: false,
      projectNameError: false,
      calHeight: 0,
      expanded: true,
      tableHeight: 0,
      expandHeight: 500,
      // 编辑和明细编辑相关数据
      parentServiceId: undefined,
      serviceRowId: '',

      serviceForm: {
        parentId: undefined,
        projectCode: '',
        projectSeq: '',
        contractSize: null,
        contractUnit: '',
        unitPrice: null,
        amount: null,
        discount: null,
        model: '',
        currency: '',
        remark: '',
        name: '',
        disabledEdit: null,
        initialSize: null,
        initialAmount: null,
      },
      filterArr: [],

      serviceRules: {
        name: [{ required: true, validator: validateServiceName, trigger: 'blur' }],
        parentId: [{ required: true, message: '请选择上级服务项', trigger: 'change' }],
        unitPrice: [{ type: 'number', message: '所填数值必须是数字类型', trigger: 'blur' }],
        amount: [{ type: 'number', message: '所填数值必须是数字类型', trigger: 'blur' }],
        discount: [{ type: 'number', message: '所填数值必须是数字类型', trigger: 'blur' }],
      },
      serviceOptions: [],
      currencyOptions: [
        { label: 'USD', value: 'USD' },
        { label: 'RMB', value: 'RMB' },
      ],
      moduleOptions: [
        { label: 'Project Management', value: 'Project Management' },
        { label: 'DM', value: 'DM' },
        { label: 'STAT', value: 'STAT' },
        { label: 'Data review meeting', value: 'Data review meeting' },
        { label: 'MED', value: 'MED' },
        { label: 'PK', value: 'PK' },
        { label: 'PV', value: 'PV' },
        { label: 'Conference travel expenses', value: 'Conference travel expenses' },
        { label: 'Pass Through', value: 'Pass Through' },
      ],
      serviceNameOptions: [],
      editShow: false,
      editDetailShow: false,
      serviceDialogStatus: '',
      dialogFormVisible: false,
      dialogBatchModifiedVisible: false,
      dialogContractVisible: false,
      dialogDateVisible: false,
      dialogChangeDateVisible: false,
      dialogPreviewResultVisible: false,
      projectArr: [], //项目列表
      dateVal: [], //时间范围
      startPlaceholder: '开始月份',
      endPlaceholder: '结束月份',
      allUser: [], //返回的所有供选择的人
      memberSelected: [], //成员
      showMemberSelectedName: [],
      dialogTitle: '',
      //校验表单规则
      ruleForm: {
        sow: '',
        category: '',
        projectName: '',
        directorSelected: '', //负责人
        chiefPMSelected: '', //首席项目管理
        boChangedAt: '',
        directorChangedAt: '',
        director: '',
        code: '',
        status: null,
        belong: '',
        type: '',
        ad: '',
      },

      rules: {
        code: [{ required: true, message: '请输入项目编号', trigger: 'blur' }],
        projectName: [{ required: true, validator: validateProjectName, trigger: 'blur' }],
        directorSelected: [{ required: true, message: '请选择负责人', trigger: 'change' }],
        chiefPMSelected: [{ required: true, message: '请选择首席项目管理人', trigger: 'change' }],
        // type: [{ validator: projectTypeIsRequired, trigger: 'change' }],
        category: [{ required: true, message: '请选择执行部门', trigger: 'change' }],
        director: [{ required: true, message: '请选择项目总监', trigger: 'change' }],
        belong: [{ required: true, message: '请选择项目归属', trigger: 'change' }],
      },
      serviceTableData: [],
      tableData: [],
      tableLoading: false,

      // 导出相关
      leadingInVisible: false,
      toggleFlag: false,
      changeTitle: '',
      choosedContractItemId: '',
      updateLoading: false,
      previewData: [], // 用于预览
      previewDialogRender: false,
      batchModifyDialogRender: false,
      newTableData: [], // 经过批量修改后的表格数据
      batchModifyData: [], // 传递给批量修改组件的数据
      belongs: ['国内', '国外'],
    };
  },

  computed: {
    choosedContractItem() {
      const defaultItem = {
        id: null,
      };
      if (this.choosedContractItemId) {
        const item = this.projectArr.find(item => item.id === this.choosedContractItemId);
        return item || this.projectArr[0] || defaultItem;
      }
      return this.projectArr[0] || defaultItem;
    },
  },

  async created() {
    await this.getSearchByUserName();
    await this.getProject();
  },

  mounted() {
    this.tableOperations.push({
      label: 'remark',
      value: 'remark',
      showTips: true,
      style: 'margin-left: -4px; margin-right: 8px',
      width: 80,
    });
  },
  methods: {
    searchKeyword(keyword) {
      this.projectArrKeyword = keyword;
      this.getProject();
    },
    updatePickDate(type) {
      if (type === 'all') {
        this.updateDate = null;
      } else {
        this.updateDate = dayFormat(+new Date(), 'YYYY-MM-DD');
      }
    },
    disabledDate(time) {
      return time.getTime() > Date.now() - 8.64e7;
    },
    changeBoDate(val) {
      let oldBoName = this.ruleForm.directorSelected ? this.allUser.filter(item => item.id === this.ruleForm.directorSelected)[0].name : '空';
      let newBoName = val ? this.allUser.filter(item => item.id === val)[0].name : '空';
      this.changeTitle = `请选择更换时间，将负责人(BO)"${oldBoName}"更换为"${newBoName}"`;
      this.appointValue = val;
      this.changeAppointDate('bo');
    },
    changePdDate(val) {
      let oldDirectorName = this.ruleForm.director ? this.allUser.filter(item => item.id === this.ruleForm.director)[0].name : '空';
      let newDirectorName = val ? this.allUser.filter(item => item.id === val)[0].name : '空';
      this.changeTitle = `请选择更换时间，将项目总监"${oldDirectorName}"更换为"${newDirectorName}"`;
      this.appointValue = val;
      this.changeAppointDate('director');
    },
    changeAppointDate(type) {
      this.appointType = type;
      this.dialogChangeDateVisible = true;
    },
    async batchModifiedDialogSubmit() {
      this.updateLoading = true;
      this.$bus.$emit('getPreviewData', previewData => {
        this.newTableData = previewData;
      });
      this.newTableData = this.newTableData.filter(item => item.name);
      this.newTableData.forEach(item => {
        item.disabledEdit = true; // 默认禁止项目编辑
        item.id === item.id ? parseInt(item.id) : null; // 转换 id 数据类型
      });
      const res = await projectManagementApi.saveServiceItems({ servings: this.newTableData }, this.choosedContractItem.id);
      if (res[2].status !== 200) {
        this.$alert('保存失败！' + res[1].message, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
        this.updateLoading = false;
        return;
      }
      this.updateLoading = false;
      this.dialogBatchModifiedVisible = false;
      this.$message.success('批量修改成功');
      this.getProject();
    },
    batchModifiedDialogCancel() {
      this.dialogBatchModifiedVisible = false;
      this.$bus.$emit('resetData'); // 重置数据
      this.batchModifyDialogRender = false; // 重置渲染
    },
    openBatchModifiedDialog() {
      this.batchModifyDialogRender = true;
      this.dialogBatchModifiedVisible = true;
    },
    openPreviewResultDialog() {
      this.$bus.$emit('getPreviewData', previewData => {
        this.previewData = previewData.filter(item => !item.isDeleted && item.name);
        this.previewDialogRender = true;
      });
      this.dialogPreviewResultVisible = true;
    },
    previewResultDialogCancel() {
      this.dialogPreviewResultVisible = false;
    },
    operateDelete() {
      this.$confirm(`本操作将删除“${this.ruleForm.projectName}”的参与人、服务项等信息，删除后不可撤销，是否继续`, '提示', {
        confirmButtonText: this.$t('commonVariables.delete'),
        cancelButtonText: this.$t('commonVariables.cancel'),
        type: 'warning',
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          operationManagementApi.deleteProject(this.choosedContractItem.id).then(res => {
            console.log(res);
            if (res[2].status !== 200) {
              this.$alert(res[1].message, '提示', {
                confirmButtonText: '确定',
                type: 'error',
              });
              return;
            }
            this.$message.success('删除成功');
            this.getProject();
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除！',
          });
        });
    },
    changeDateDialogCancel() {
      this.updateDate = null;
      this.appointType = '';
      this.appointValue = null;
      this.updateDateType = false;
      this.dialogChangeDateVisible = false;
    },
    async changeDateDialogSubmit() {
      if (this.updateDateType && !this.updateDate) {
        this.$message.error('请先选择一个指定日期');
        return;
      }
      this.appointType === 'bo' ? (this.ruleForm.directorSelected = this.appointValue) : (this.ruleForm.director = this.appointValue);
      if (this.updateDate) {
        this.appointType === 'bo' ? (this.ruleForm.boChangedAt = this.updateDate) : (this.ruleForm.directorChangedAt = this.updateDate);
      }

      this.changeDateDialogCancel();
    },
    operateProject() {
      this.dialogDateVisible = true;
      this.choosedDate = dayFormat(+new Date(), 'YYYY-MM-DD');
    },
    async dateDialogSubmit() {
      let operateAPI = this.ruleForm.status === 0 ? 'endProject' : 'resetProject';
      const res = await operationManagementApi[operateAPI]({ time: this.choosedDate }, this.choosedContractItem.id);
      if (res[2].status !== 200) {
        this.$message.error(res[1].message);
        return;
      }
      this.$message.success('提交操作成功');
      this.getProject();
      this.dateDialogCancel();
    },
    dateDialogCancel() {
      this.choosedDate = '';
      this.dialogDateVisible = false;
    },
    contractDialogCancel() {
      this.contractNames = [];
      this.submitContractNames = [];
      this.getProject();
      this.dialogContractVisible = false;
    },
    emitContractData(data) {
      this.submitContractNames = [...data];
    },
    async contractDialogSubmit() {
      const res = await projectManagementApi.batchSaveContractNames({ contractNames: this.submitContractNames }, this.choosedContractItem.id);
      if (res[2].status !== 200) {
        this.$message.error(res[1].message);
        return;
      }

      this.$message.success('提交成功');
      this.contractDialogCancel();
    },
    async addContractName() {
      const res = await scheduleManagementApi.getContractNames({ projectId: this.choosedContractItem.id });
      if (res[2].status !== 200) {
        this.$message.error(res[1].message);
        return;
      }

      this.fillDataArr[0].optionGroup = [...res[0].codes];
      this.contractNames = this.choosedContractItem?.contractNames?.length ? JSON.parse(JSON.stringify(this.choosedContractItem.contractNames)) : [];
      this.dialogContractVisible = true;
    },

    //获取该项目服务项的tableList数据
    async getServiceByProject(id) {
      this.tableLoading = true;
      let [data] = await projectManagementApi.getServiceByProject({ projectIdEq: id });
      this.serviceTableData = [...data.servings];
      this.tableData = [...data.servings];
      this.serviceOptions = [...data.servings];
      let [data2] = await projectManagementApi.getServiceListByProject({ projectIdEq: id });
      this.batchModifyData = [...data2.servings];
      this.lastChoosedItem = Object.assign({}, this.choosedContractItem);
      this.tableLoading = false;
    },

    expandOver() {
      this.calTableDom();
    },
    calTableRangeWidth() {
      this.tableWidth = window.innerWidth - this.$refs['tree-sub'].offsetWidth - 16 - 28 - (this.$store.getters.sidebar.opened ? 208 : 55);
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    calTableDom() {
      this.$nextTick(() => {
        // 上导航(包含内外边距) 路由菜单滑轨(包含内外边距) ref->top-box,header bottom-box的内边距和外边距
        this.otherDomHeight = (this.$refs.headerBtns?.offsetHeight ?? 0) + (this.$refs.header?.offsetHeight ?? 0) + 32 + 50 + 16 + 12 + 5 + 24;
        this.tableHeight = window.innerHeight - this.otherDomHeight;
      });
    },
    // 复制相关合同信息到空白行的同步table操作
    loadTableData(tableData) {
      this.tableData = JSON.parse(JSON.stringify(tableData));
    },

    indexMethod(index) {
      return index + 1 < 10 ? index + 1 : index + 1;
    },
    serviceCancel() {
      this.serviceForm = {
        parentId: undefined,
        projectCode: '',
        contractSize: null,
        contractUnit: '',
        unitPrice: null,
        amount: null,
        discount: null,
        model: '',
        currency: '',
        remark: '',
        projectSeq: '',
        name: '',
        disabledEdit: null,
        initialSize: null,
        initialAmount: null,
      };
      this.parentServiceId = undefined;
      this.$refs['serviceForm'].resetFields();
      this.dialogFormVisible = false;
    },
    async onNoticeDialogSubmit() {
      this.$refs.serviceForm.validate(async valid => {
        if (!valid) {
          return;
        }
        if (isNaN(Number(this.serviceForm.contractSize))) {
          this.$message.error('填写的数量必须是数字类型');
          return;
        }
        if (!this.serviceForm.contractSize) {
          this.serviceForm.contractSize = null;
        }
        if (!this.choosedContractItem.id) {
          this.$message.error('请先选择具体项目后再进行操作');
          return;
        }

        const [validateRepeat] = await projectManagementApi.identifyServiceName({
          project: this.choosedContractItem.id,
          id: this.serviceForm.id ? this.serviceForm.id : null,
          name: this.serviceForm.name,
          projectCode: this.serviceForm.projectCode,
        });
        if (!validateRepeat.valid) {
          this.serviceNameValidateError = true;
        }
        this.$refs.serviceForm.validate(async valid => {
          if (!valid) {
            this.serviceNameValidateError = false;
            return;
          }
          this.serviceForm.parentId = this.parentServiceId ? this.parentServiceId : undefined;
          const res = await projectManagementApi.saveServiceItems({ servings: [this.serviceForm] }, this.choosedContractItem.id);
          if (res[2].status !== 200) {
            this.$alert('保存失败！' + res[1].message, '提示', {
              confirmButtonText: '确定',
              type: 'error',
            });
            return;
          }
          await this.getServiceByProject(this.choosedContractItem.id);
          this.serviceCancel();
          this.$message.success(this.$t('commonVariables.submittedSuccessfully'));
        });
      });
    },
    changeMemberSelected(vals, ids) {
      this.showMemberSelectedName = [...vals];
      this.memberSelected = [...ids];
    },
    //按名字检索用户
    async getSearchByUserName() {
      let [data] = await projectManagementApi.getSearchUserName({ page: 1, pagesize: 99999 });
      let [typeData] = await projectManagementApi.getProjectTypeList();
      if (data) this.allUser = data.user;
      if (typeData) this.projectType = typeData.types;
    },

    //获取所有项目列表
    async getProject() {
      const loading = this.$loading({
        lock: true,
        text: '正在加载中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
      });

      operationManagementApi
        .getOperateProject({ statusEq: this.projectStatus, page: 1, pagesize: 99999, keyword: this.projectArrKeyword })
        .then(async res => {
          let data = Object.assign({}, res[0]);
          if (!data) {
            loading.close();
            return;
          }

          this.projectArr = [...data.project];
          if (this.projectArr.length) {
            this.tableLoading = true;
            await this.getProDetail(this.choosedContractItem.id);

            this.getServiceByProject(this.choosedContractItem.id);
            loading.close();
          } else {
            this.tableLoading = false;
            loading.close();
            // this.choosedContractItem = { id: null };
            this.resetFieldData();
          }
        });
    },

    //获取项目的详情
    getProDetail(id) {
      return new Promise(resolve => {
        this.boxLoading = true;
        projectManagementApi.getProjectDetail({ id }).then(async item => {
          if (!item[1].data) return;
          this.memberSelected = item[1].data.worker.map(i => i.id);
          this.ruleForm.code = item[1].data.code;
          this.ruleForm.projectName = item[1].data.name;
          this.ruleForm.category = item[1].data.category;
          this.ruleForm.status = item[1].data.status;

          this.ruleForm.sow = item[1].data.sow;
          this.dateVal = [item[1].data.start, item[1].data.end];
          this.ruleForm.directorSelected = item[1].data.bo?.length ? Number(item[1].data.bo[0].id) : null;
          this.ruleForm.chiefPMSelected = item[1].data.pm?.length ? Number(item[1].data.pm[0].id) : null;
          this.ruleForm.director = item[1].data.director?.length ? Number(item[1].data.director[0].id) : null;
          this.ruleForm.ad = item[1].data.ad?.length ? Number(item[1].data.ad[0].id) : null;
          this.ruleForm.boChangedAt = item[1].data.boChangedAt;
          this.ruleForm.directorChangedAt = item[1].data.directorChangedAt;
          this.showMemberSelectedName = item[1].data.worker.map(i => i.name);
          this.ruleForm.type = item[1].data.type;
          this.ruleForm.belong = item[1].data.belong;
          this.boxLoading = false;
          resolve();
        });
      });
    },
    // 变更状态刷新列表
    transfromStatus(val) {
      this.projectStatus = val;
      this.getProject();
    },
    resetFieldData() {
      this.serviceTableData = [];
      this.tableData = [];
      this.serviceOptions = [];
      this.lastChoosedItem = null;
      this.ruleForm = {
        sow: '',
        category: '',
        projectName: '',
        directorSelected: '', //负责人
        chiefPMSelected: '', //首席项目管理
        boChangedAt: '',
        directorChangedAt: '',
        director: '',
        code: '',
        status: null,
        belong: '',
        type: '',
        ad: '',
      };
    },
    //获取选择的那个项目
    async getChoosedContractItem(i, type, bool) {
      this.choosedContractItemId = i?.id;
      this.lastChoosedItem = Object.assign({}, this.choosedContractItem);
      // this.choosedContractItem = Object.assign({}, i);
      this.contractNames = this.choosedContractItem?.contractNames?.length ? JSON.parse(JSON.stringify(this.choosedContractItem.contractNames)) : [];
      if (_.isEmpty(this.choosedContractItem)) {
        this.resetFieldData();
      }
      if (bool) {
        return;
      }
      await this.getProDetail(this.choosedContractItem.id);
      this.getServiceByProject(this.choosedContractItem.id);

      this.$refs['ruleForm'].clearValidate();
    },
    //添加项目触发更新
    addProject() {
      this.getProject();
    },

    //撤销更改
    cancelEdit() {
      this.getProDetail(this.choosedContractItem.id);
      this.$message.info('已取消修改,并重置数据状态');
    },
    async saveFormEdit() {
      if (!this.choosedContractItem.id) {
        this.$message.error('请先选择具体项目后再进行操作');
        return;
      }
      const res = await projectManagementApi.verifyUniqueness({ id: this.choosedContractItem.id, name: this.ruleForm.projectName });
      // 项目名称未通过校验
      if (!res[0].valid) {
        // 项目名称非空，代表项目名称重复
        if (this.ruleForm.projectName) {
          this.projectNameError = true;
          // 激活校验
          this.$refs.ruleForm.validate(() => {
            this.$message.warning('项目名称重复，请修改后再次提交保存');
          });
        } else {
          // 项目名称为空
          this.$refs.ruleForm.validate(() => {
            this.$message.warning('项目名称未填，请填写后再次提交保存');
          });
        }
        return;
      }
      this.projectNameError = false;
      // 项目名称通过校验
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) {
          if (
            !this.ruleForm.directorSelected ||
            !this.ruleForm.chiefPMSelected ||
            !this.ruleForm.code ||
            !this.ruleForm.director ||
            !this.ruleForm.belong ||
            !this.ruleForm.category
          ) {
            this.$message.warning('有必填信息未填，请填写后再次提交保存');
            return;
          }
        }
        let q = {
          id: this.choosedContractItem.id,
          name: this.ruleForm.projectName,
          start: this.dateVal[0] ? dayFormat(this.dateVal[0], 'YYYY-MM-DD') : '',
          end: this.dateVal[1] ? dayFormat(this.dateVal[1], 'YYYY-MM-DD') : '',
          bo: this.ruleForm.directorSelected ? [this.ruleForm.directorSelected] : [],
          pm: this.ruleForm.chiefPMSelected ? [this.ruleForm.chiefPMSelected] : [],
          category: this.ruleForm.category,
          status: this.ruleForm.status,
          sow: this.ruleForm.sow,
          boChangedAt: this.ruleForm.boChangedAt,
          directorChangedAt: this.ruleForm.directorChangedAt,
          ad: this.ruleForm.ad ? [this.ruleForm.ad] : [],
          director: this.ruleForm.director ? [this.ruleForm.director] : [],
          code: this.ruleForm.code,
          worker: this.memberSelected,
          type: this.ruleForm.type,
          belong: this.ruleForm.belong,
        };
        // this.$refs.ruleForm.validate(async valid => {
        //   if (valid) {
        //     let [data] = await projectManagementApi.getSaveProjectEdit(q);
        //     if (!data || Object.keys(data).length === 0) {
        //       this.$message.error('保存失败！');
        //       return;
        //     }
        //     this.$message.success('保存成功！');
        //     // this.sortOutResData(data);
        //     await this.getProject();
        //   } else {
        //     return false;
        //   }
        // });
        let [data] = await projectManagementApi.getSaveProjectEdit(q);
        if (!data || Object.keys(data).length === 0) {
          this.$message.error('保存失败！');
          return;
        }
        this.$message.success('保存成功！');
        // this.sortOutResData(data);
        await this.getProject();
      });
    },
    //保存编辑后的项目
    async saveEdit() {
      this.saveFormEdit();
    },
    // sortOutResData(data) {
    //   this.ruleForm.projectName = data.name;
    //   this.dateVal = [data.start, data.end];

    //   this.memberSelected = data.worker.map(i => i.id);
    //   this.ruleForm.directorSelected = data.bo?.length ? Number(data.bo[0].id) : '';
    //   this.ruleForm.chiefPMSelected = data.pm?.length ? Number(data.pm[0].id) : '';
    //   this.ruleForm.category = data.category;
    //   this.ruleForm.status = data.status;
    //   this.ruleForm.sow = data.sow;
    //   this.ruleForm.boChangedAt = data.boChangedAt;
    //   this.ruleForm.directorChangedAt = data.directorChangedAt;
    //   this.ruleForm.ad = data.ad?.length ? Number(data.ad[0].id) : '';
    //   this.ruleForm.director = data.director?.length ? Number(data.director[0].id) : '';
    //   this.showMemberSelectedName = data.worker.map(i => i.name);
    //   this.ruleForm.belong = data.belong;
    // },
    async editShowDialog(serviceId) {
      this.serviceRowId = serviceId;
      let templateForm = null;
      for (let i = 0; i < this.serviceTableData.length; i++) {
        if (this.serviceTableData[i].children?.length) {
          const getItem = this.serviceTableData[i].children.find(val => {
            return val.id == serviceId;
          });
          if (getItem) {
            templateForm = Object.assign({}, getItem);
            break;
          } else if (this.serviceTableData[i].id == serviceId) {
            templateForm = Object.assign({}, this.serviceTableData[i]);
            break;
          }
        } else if (this.serviceTableData[i].id == serviceId) {
          templateForm = Object.assign({}, this.serviceTableData[i]);
          break;
        }
      }
      this.serviceForm = Object.assign({}, templateForm);
      this.parentServiceId = templateForm?.parentId ? templateForm.parentId : '';
      this.dialogTitle = this.$t('projectManagement.editServiceItem');
      this.dialogFormVisible = true;
    },
    //添加服务项
    addServiceItem() {
      this.dialogTitle = this.$t('projectManagement.createNewServiceItem');

      this.serviceForm.disabledEdit = true;

      this.dialogFormVisible = true;
    },

    //保存运营管理的服务项
    async saveAllServiceItems() {
      if (!this.choosedContractItem.id) {
        this.$message.error('请先选择具体项目后再进行操作');
        return;
      }
      const res = await projectManagementApi.saveServiceItems({ servings: [...this.serviceTableData] }, this.choosedContractItem.id);
      if (res[2].status !== 200) {
        this.$alert('保存失败！' + res[1].message, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
        return;
      }
      this.$message.success('保存成功！');
      this.getServiceByProject(this.choosedContractItem.id);
    },
    refreshList(id) {
      this.getServiceByProject(id ? id : this.choosedContractItem.id);
    },
    async deleteRow(deleteId) {
      if (!this.choosedContractItem.id) {
        this.$message.error('请先选择具体项目后再进行操作');
        return;
      }
      let templateForm = null;
      for (let i = 0; i < this.serviceTableData.length; i++) {
        if (this.serviceTableData[i].children?.length) {
          const getItem = this.serviceTableData[i].children.find(val => {
            return val.id == deleteId;
          });
          if (getItem) {
            templateForm = Object.assign({}, getItem);
            break;
          } else if (this.serviceTableData[i].id == deleteId) {
            templateForm = Object.assign({}, this.serviceTableData[i]);
            break;
          }
        } else if (this.serviceTableData[i].id == deleteId) {
          templateForm = Object.assign({}, this.serviceTableData[i]);
          break;
        }
      }
      templateForm.isDeleted = true;
      projectManagementApi.saveServiceItems({ servings: [templateForm] }, this.choosedContractItem.id).then(res => {
        if (res[2].status !== 200) {
          this.$message.error(res[1].message);
          return;
        }
        this.getServiceByProject(this.choosedContractItem.id);
        this.$message.success('删除成功');
      });
    },
    // 设置服务项据实更新
    async setJustSettlement(serviceId) {
      const res = await projectManagementApi.setJustSettlement(serviceId);
      if (res[2].status !== 200) {
        this.$alert('设置据实结算失败！' + res[1].message, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
        return;
      }
      this.$message.success('设置据实结算成功！');
      this.getProject();
    },
  },
};
</script>

<style lang="scss" scoped>
.is-edit-disabled {
  ::v-deep .el-input__inner {
    background-color: #fff !important;
  }
}
::v-deep .el-dialog__header {
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  justify-content: center;
}
::v-deep.el-select-dropdown__item {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}
.project-management__page {
  max-height: 100%;
  display: flex;
  .tree-sub {
    margin-left: 24px;
    .tree-menu {
      ::v-deep .tree-menu__box {
        .tit {
          // height: 68px;
          // line-height: 80px;
          padding-top: 22px;
          margin-bottom: 6px;
        }
      }
    }
  }

  .app-box {
    flex: 1;
    margin: 0 16px;
    background-color: #fff;
    overflow-y: hidden;
    border-radius: 2px;
    width: 100%;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: auto;
      padding-bottom: 12px;
      height: 51px;

      .checkHeaderTit {
        cursor: pointer;
        font-size: 14px;
        color: #2d8cf0;
        margin-right: auto;
        padding-left: 10px;
        line-height: 36px;
      }
      .matchbtn {
        color: #1890ff !important;
        background: #fff !important;
        border-color: #70abf8 !important;
      }
      .matchbtn:hover {
        background: #e6f1fc !important;
      }

      .operatorHeaderTit {
        font-size: 16px;
        font-weight: 700;
        margin-right: auto;
        padding-left: 10px;
        line-height: 36px;
      }
      .checkHeaderBtn {
        height: 36px;
      }
      .right-container {
        display: flex;
        align-items: center;
        margin-left: auto;
        .optBtn {
          margin-right: 10px;
        }
        .expandControl {
          padding-right: 16px;
          color: #409eff;
          cursor: pointer;
          font-size: 14px;
          span {
            padding-right: 5px;
          }
        }
        .header-btns {
          height: 32px;
          font-size: 12px;
          .savebtn {
            margin-left: 16px;
          }
        }
      }
    }

    .top-box {
      background-color: #fff;
      padding: 12px 24px 32px;
      padding-bottom: 8px;
      border-radius: 2px;
      .header {
        border-bottom: 1px solid #e8eaec;
        .top-header-tit {
          font-size: 16px;
          font-weight: 600;
          color: #19233b;
          p {
            display: inline-block;
            margin: 0;
          }
          .tit-container {
            margin: 0;
            display: inline-block;
          }
          .box {
            display: inline-block;
            position: relative;
            // min-width: 92px;
            min-height: 24px;
            margin-left: 8px;

            background: #f5f7fa;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2d8cf0;

            .circle {
              position: absolute;
              top: calc(50% - 3px);
              left: 12px;
              border-radius: 50%;
              width: 6px;
              height: 6px;
              background: #2d8cf0;
            }
            .currentRord {
              color: #515a6e;

              padding-left: 24px;
              padding-right: 12px;
              line-height: 24px;
            }
          }
          .contractDialog {
            display: inline-block;
            height: 24px;
            .exportIcon {
              vertical-align: middle;
              margin-left: 32px;
              width: 14px;
              height: 14px;
              border: none;
              color: #2d8cf0;
              line-height: 24px;
            }
            .checkContractName {
              font-weight: normal;
              font-size: 14px;
              color: #2d8cf0;
              cursor: pointer;
              margin-left: 6px;
            }
          }
        }
        .tit-container {
          margin: 0;
          display: inline-block;
        }
      }

      .edit_from {
        display: flex;
        flex: auto;
        justify-content: flex-start;
        flex-wrap: wrap;
        font-size: 14px;

        ::v-deep .el-form-item__content {
          line-height: 32px;
        }

        .el-form-item {
          margin: 0;
          flex: auto;
          margin-right: 30px;
          max-width: 220px;
          min-width: 208px;
          padding: 10px 0;
          ::v-deep .el-form-item__label {
            color: #515a6e;
            font-weight: normal;
            font-size: 14px;
            height: 20px;
            padding: 0;
            line-height: 20px;
            margin-top: 0;
            margin-bottom: 7px;
          }
          .projInput {
            width: 230px !important;
            ::v-deep .el-range-input {
              font-size: 12px;
            }
          }
          ::v-deep .el-select {
            width: 100%;
          }
          ::v-deep .el-date-editor--daterange {
            width: 100%;
          }
        }
      }
    }
    .heat-shield {
      background: #f8f8f8f9;
      height: 16px;
      width: 100%;
    }
    .bottom-box {
      padding: 12px 24px 12px;
    }
    .serviceEdit {
      ::v-deep .el-dialog__footer {
        padding-top: 0px;
        padding-right: 32px;
        padding-bottom: 20px;
      }
      .serviceForm {
        padding: 24px 32px 24px 30px;
        ::v-deep .el-input {
          width: 100%;
        }
        ::v-deep .el-form-item__label {
          font-weight: normal;
          color: #515a6e;
        }
        .specialItem {
          display: inline-block;
          width: 250px;
        }
        .remarkItem {
          display: inline-block;
          width: 100%;
          .remarkTips {
            padding-left: 10px;
            line-height: 1;
            color: #2d8cf0;
            font-size: 12px;
          }
        }
      }
    }
    .checkContractDialog {
      .editRowContainer {
        padding: 32px;
        padding-top: 20px;
        ::v-deep .el-table__header {
          width: 100%;
        }
      }
    }
    .selectDateDialog {
      ::v-deep .el-dialog {
        .el-date-editor {
          width: 248px;
        }
        .el-dialog__header {
          border-bottom: none;
          justify-content: flex-start;
        }
        .el-dialog__footer {
          padding-right: 20px;
        }
      }
      .block {
        padding-left: 20px;
        .demonstration {
          font-size: 16px;
          font-weight: 500;
          color: #17233d;
        }
      }
      .dateRadioGroup {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        .sub {
          padding: 10px 0;
        }
      }
    }
    .batchModifiedDialog {
      height: 932px;
      margin-left: 160px;
      ::v-deep .el-dialog {
        border-radius: 8px !important;
      }
    }
  }

  ::v-deep .el-input__inner {
    color: #515a6e;
    height: 32px;
    line-height: 32px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    font-size: 14px;
  }
  ::v-deep .el-form-item__error {
    padding-top: 0;
    padding-bottom: 12px;
  }
  ::v-deep .el-input__suffix {
    display: flex;
    align-items: center;
  }

  ::v-deep .el-icon-arrow-up {
    line-height: 32px;
  }
  ::v-deep .el-icon-date {
    line-height: 32px;
  }
}

@media screen and (min-width: 350px) and (max-width: 900px) {
  ::v-deep .tree-menu__box {
    max-width: 188px !important;
  }
}
@media screen and (min-width: 900px) and (max-width: 1550px) {
  ::v-deep .tree-menu__box {
    max-width: 198px !important;
  }
  .tree-sub {
    margin-left: 16px !important;
  }
  .app-box {
    flex: auto !important;
    background-color: #fff;
    padding: 0 !important;
    border-radius: 2px;
    margin: 0 16px !important;
    margin-right: 12px !important;
    margin-top: 0 !important;
  }
  .bottom-box {
    padding: 8px 12px !important;
  }
}
// ::v-deep .el-dialog__title {
//   font-weight: 600;
//   color: #17233d;
// }
::v-deep .el-input-number {
  .el-input__inner {
    text-align: start;
  }
}
</style>
