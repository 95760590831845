<template>
  <div id="contractManagement" v-if="!authPick" :style="{ width: tableWidth + ' !important' }">
    <filter-pane ref="filterPane">
      <template v-slot:filter>
        <div class="headerContainer">
          <div class="top-header-tit">
            <p class="editContract">{{ $t('contractManagement.editContract') }}</p>
            <div @click="toggleExpand" class="tit-container expandControl">
              <span>{{ expanded ? $t('commonVariables.fold') : $t('commonVariables.unfold') }}</span
              ><i :class="expanded ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
            </div>
          </div>
          <div class="buttonGroup">
            <el-button v-if="authGroup.create" type="primary" size="mini" class="addButton" @click="addContract">
              {{ $t('contractManagement.newContract') }}
            </el-button>
          </div>
        </div>
      </template>
    </filter-pane>
    <div class="searchFormContainer" ref="searchFormContainer">
      <TransitionCollapse @expandOver="expandOver" @retractOver="expandOver" v-show="expanded" :expandHeight="expandHeight">
        <el-form :model="dataForm" ref="dataForm" class="dataForm" label-width="60px" label-position="top">
          <el-form-item prop="contractCodeCont" :label="$t('contractManagement.contractCode')">
            <el-input v-model="dataForm.contractCodeCont" placeholder="请输入合同编号"></el-input>
          </el-form-item>

          <el-form-item prop="projectCodeCont" :label="$t('contractManagement.projectCode')">
            <el-input v-model="dataForm.projectCodeCont" placeholder="请输入项目编号"></el-input>
          </el-form-item>

          <el-form-item prop="customerIdEq" :label="$t('contractManagement.customerName')">
            <el-select size="mini" v-model="dataForm.customerIdEq" filterable clearable>
              <el-option v-for="item in customerOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="bdIdEq" :label="$t('contractManagement.bdNames')">
            <el-select v-model="dataForm.bdIdEq" filterable clearable style="width: 100%">
              <el-option v-for="item in allBd" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="contractTypeEq" :label="$t('contractManagement.contractType')">
            <el-select v-model="dataForm.contractTypeEq" filterable clearable style="width: 100%">
              <el-option v-for="item in contractTypeOptions" :key="item.id" :label="$t(item.name)" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item prop="statusEq" :label="$t('contractManagement.status')">
            <el-select v-model="dataForm.statusEq" filterable clearable style="width: 100%">
              <el-option v-for="item in statusTypeOptions" :key="item.id" :label="$t(item.name)" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="dataFormItem" prop="nameCont" :label="$t('contractManagement.projectName')">
            <el-input v-model="dataForm.nameCont" placeholder="请输入项目名称"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contractManagement.orderDate')">
            <el-date-picker
              v-model="dateVal"
              class="el-date-picker"
              type="daterange"
              range-separator="至"
              :unlink-panels="true"
              :start-placeholder="startPlaceholder"
              :end-placeholder="endPlaceholder"
            ></el-date-picker>
          </el-form-item>
          <el-form-item class="button-group">
            <el-button class="btn" size="mini" type="default" @click="resetForm">{{ $t('commonVariables.reset') }}</el-button>
            <el-button class="btn" size="mini" type="primary" @click="filterData">{{ $t('ResourceManagement.customer.screen') }}</el-button>
          </el-form-item>
        </el-form>
      </TransitionCollapse>
    </div>
    <div class="heat-shield"></div>

    <EditServiceItemTable
      :shrinkage="true"
      @pageChange="pageChange"
      :applyComp="'contractManagement'"
      :tableLoading.sync="tableLoading"
      :serviceTableData="serviceTableData"
      :tableHeight="tableHeight"
      @deleteData="deleteData"
      :deleteWarnTips="$t('contractManagement.deleteWarning')"
      :saveKey.sync="saveKey"
      :page.sync="dataForm.page"
      :pagesize.sync="dataForm.pageSize"
      @searchForm="resetData"
      :chooseInputArr="chooseInputArr"
      @editServiceItem="editRow"
      :count="count"
      :authGroup="authGroup"
    ></EditServiceItemTable>

    <el-drawer
      class="record"
      :with-header="false"
      :wrapperClosable="false"
      :show-close="false"
      :modal="true"
      :title="recordForm.id ? $t('contractManagement.editContract') : $t('contractManagement.newContract')"
      :visible.sync="drawerVisable"
      :size="$i18n.locale == 'en' ? 900 : 860"
    >
      <div class="drawerHeaderContainer">
        <p>{{ recordForm.id ? $t('contractManagement.editContract') : $t('contractManagement.newContract') }}</p>
        <div class="addContract-button-group">
          <el-button class="btn" size="mini" type="default" @click="closeDrawer($t('contractManagement.closeDrawerTips'))">{{
            $t('commonVariables.cancel')
          }}</el-button>
          <el-button class="btn" size="mini" type="primary" @click="saveAllServiceItems">{{ $t('commonVariables.save') }}</el-button>
        </div>
        <!-- <i class="el-icon-close closeIcon" @click="closeDrawer"></i> -->
      </div>
      <el-form ref="recordForm" label-position="right" :rules="formRules" :label-width="$i18n.locale == 'en' ? '120px' : '90px'" :model="recordForm">
        <div class="recordForm">
          <div class="leftForm">
            <el-form-item prop="projectCode" :label="$t('contractManagement.projectCode')">
              <el-input v-model="recordForm.projectCode" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="customerId" :label="$t('contractManagement.customerCode')">
              <el-select size="mini" v-model="recordForm.customerId" filterable clearable @change="setCustomerName">
                <el-option v-for="item in customerOptions" :key="item.id" :label="item.code" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="contractType" :label="$t('contractManagement.contractType')">
              <el-select v-model="recordForm.contractType" filterable clearable style="width: 100%">
                <el-option v-for="item in contractTypeOptions" :key="item.id" :label="$t(item.name)" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="shortName" :label="$t('contractManagement.projectShortName')">
              <el-input v-model="recordForm.shortName" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="currency" :label="$t('contractManagement.currency')">
              <el-select v-model="recordForm.currency" filterable clearable style="width: 100%">
                <el-option v-for="item in currencyTypeOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="year" :label="$t('contractManagement.year')">
              <el-date-picker size="mini" type="year" :value-format="'yyyy'" v-model="recordForm.year" class="el-date-picker"></el-date-picker>
            </el-form-item>
            <el-form-item prop="status" :label="$t('contractManagement.status')">
              <el-select v-model="recordForm.status" filterable clearable style="width: 100%">
                <el-option v-for="item in statusTypeOptions" :key="item.id" :label="$t(item.name)" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="rightForm">
            <el-form-item prop="contractCode" :label="$t('contractManagement.contractCode')">
              <el-input v-model="recordForm.contractCode" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="customerName" :label="$t('ResourceManagement.customer.name')">
              <el-input v-model="recordForm.customerName" disabled></el-input>
            </el-form-item>
            <el-form-item prop="name" :label="$t('contractManagement.projectName')">
              <el-input v-model="recordForm.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="period" :label="$t('contractManagement.period')">
              <el-input v-model="recordForm.period" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="amount" :label="$t('contractManagement.amount')">
              <el-input v-model.number="recordForm.amount" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item prop="orderDate" :label="$t('contractManagement.orderDate')">
              <el-date-picker
                size="mini"
                type="date"
                v-model="recordForm.orderDate"
                :value-format="'yyyy-MM-dd'"
                class="el-date-picker"
              ></el-date-picker>
            </el-form-item>

            <el-form-item prop="bdIds" :label="$t('contractManagement.bdNames')">
              <memberSelect :projectUpdate="true" :memberSelected="memberSelected" :allUser="allBd" @changeMemberSelected="changeBdSelected" />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div class="addDepartment">
        <div class="addHeaderContainer">
          <p class="left">{{ $t('contractManagement.addDepartment') }}</p>
          <p class="right" @click="addItem"><i class="el-icon-circle-plus-outline addIcon"></i>{{ $t('contractManagement.addDepartment') }}</p>
        </div>
        <div class="addContainer">
          <div class="labelContainer">
            <p>请选择部门</p>
            <p>请选择部门BO</p>
            <p>请选择部门PM</p>
            <div class="a-placeholder"></div>
          </div>
          <div class="mainContainer" v-for="(item, idx) in addDepartmentSums" :key="idx">
            <div class="department">
              <el-select
                @change="chooseDp(addDepartmentSums[idx].group, idx)"
                v-model="addDepartmentSums[idx].group"
                filterable
                :clearable="false"
                style="width: 100%"
              >
                <el-option v-for="item in departmentOptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
              </el-select>
            </div>
            <div class="bo">
              <el-select v-model="addDepartmentSums[idx].boId" filterable clearable style="width: 100%">
                <el-option v-for="val in addDepartmentType[addDepartmentSums[idx].group]" :key="val.id" :label="val.name" :value="val.id">
                </el-option>
              </el-select>
            </div>
            <div class="pm">
              <el-select v-model="addDepartmentSums[idx].pmId" filterable clearable style="width: 100%">
                <el-option v-for="item in addDepartmentType[addDepartmentSums[idx].group]" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="removeOperation">
              <i class="el-icon-remove-outline removeOp" @click="removeDepartment(idx)"></i>
            </div>
          </div>
        </div>
      </div>
      <el-form class="uploadForm" ref="uploadForm" label-position="right" :label-width="$i18n.locale == 'en' ? '160px' : '80px'">
        <el-form-item :label="$t('contractManagement.preContractUrl')">
          <el-upload
            class="upload-excel"
            action="string"
            ref="preUpload"
            :before-upload="preBeforeUpload"
            :before-remove="preRemove"
            :http-request="preUpload"
            :file-list="preFileList"
          >
            <el-button class="btn" size="mini" type="primary">{{ $t('contractManagement.uploadPreContract') }}</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item :label="$t('contractManagement.finalContractUrl')">
          <el-upload
            class="upload-excel"
            action="string"
            ref="finUpload"
            :before-upload="finBeforeUpload"
            :before-remove="finRemove"
            :http-request="finUpload"
            :file-list="finFileList"
          >
            <el-button class="btn" size="mini" type="primary">{{ $t('contractManagement.uploadSigningContract') }}</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>

  <div v-else>
    <Empty v-if="shouldShowErrimg" class="show_empty" :image="errImg" :description="errInfo" />
  </div>
</template>

<script>
import resourceManagement from '@/api/modules/resourceManagement.api.js';
import contractManagement from '@/api/modules/contractManagement.api.js';

import TransitionCollapse from '@/components/common/TransitionCollapse.vue';
import Empty from '@/components/Empty/index.vue';
import FilterPane from '@/views/project-data/components/FilterPane';
import EditServiceItemTable from './components/EditServiceItemTable';

import memberSelect from '@/components/memberSelect/index.vue';
import { dayFormat } from '@/utils/time-tools';

import OSS from 'ali-oss';

import { mixinsResource } from '@/mixins/resourceData';

export default {
  components: {
    EditServiceItemTable,
    FilterPane,
    Empty,
    memberSelect,
    TransitionCollapse,
  },
  name: 'ClientManagement',
  mixins: [mixinsResource],
  data() {
    const validateCode = (rule, value, callback) => {
      if (this.codeError) {
        callback(new Error('编号已被占用'));
      } else if (!value) {
        callback(new Error('请输入编号名称'));
      } else {
        callback();
      }
    };
    const validateName = (rule, value, callback) => {
      if (this.nameError) {
        callback(new Error('名称已被占用'));
      } else if (!value) {
        callback(new Error('请输入方案名称'));
      } else {
        callback();
      }
    };
    return {
      testData: false,
      repeatCheck: false,
      expanded: true,
      authGroup: {
        read: false,
        create: false,
        delete: false,
        edit: false,
      },
      startPlaceholder: '开始月份',
      endPlaceholder: '结束月份',
      dateVal: [],
      chooseInputArr: [
        {
          prop: 'contractType',
        },
        {
          prop: 'contractCode',
        },
        {
          prop: 'projectCode',
        },
        {
          prop: 'status',
        },
        {
          prop: 'customerName',
        },
      ],
      codeError: false,
      nameError: false,
      dialogRules: {
        code: [{ required: true, type: 'string', validator: validateCode, trigger: 'blur' }],
        name: [{ required: true, type: 'string', validator: validateName, trigger: 'blur' }],
      },
      formRules: {
        name: [{ required: true, type: 'string', message: '请输入', trigger: 'blur' }],
      },
      customer_row: {},
      drawerVisable: false,
      allUser: [],
      allBd: [],
      sponsorOption: [],
      customerOptions: [],
      departmentOptions: [
        { name: 'MED', id: 'med' },
        { name: 'PK', id: 'pk' },
        { name: 'PV', id: 'pv' },
        { name: 'CO', id: 'co' },
        { name: 'STAT', id: 'stat' },
        { name: 'DM', id: 'dm' },
      ],
      currencyTypeOptions: [
        { name: 'RMB', id: 'rmb' },
        { name: 'USD', id: 'usd' },
      ],
      contractTypeOptions: [
        {
          name: 'contractManagement.sales',
          id: 's',
        },
        {
          name: 'contractManagement.purchase',
          id: 'p',
        },
      ],
      statusTypeOptions: [
        {
          name: 'contractManagement.doingProjectCount',
          id: 'doing',
        },
        {
          name: 'contractManagement.finishProjectCount',
          id: 'finish',
        },
      ],
      serviceTableData: [],
      count: 0,
      showEmpty: false,
      tableLoading: false,
      dataForm: {
        page: 1,
        pageSize: 20,
        contractCodeCont: '',
        projectCodeCont: '',
        customerIdEq: '',
        contractTypeEq: '',
        statusEq: '',
        bdIdEq: '',
        nameCont: '',
      },
      memberSelected: [],
      showMemberSelectedName: [],
      saveKey: false,
      recordData: [],
      authPick: false,
      errImg: require('@/assets/nodata.png'),
      errInfo: '这里什么都没有发现',
      shouldShowErrimg: false,
      // 抽屉
      recordForm: {
        id: '',
        projectCode: '',
        contractCode: '',
        customerId: '',
        contractType: '',
        name: '',
        shortName: '',
        currency: '',
        amount: 0,
        year: '',
        orderDate: '',
        status: '',
        contractStaffs: [],
        bdIds: [],
        preContractUrls: [],
        finalContractUrls: [],
        period: '',
      },
      addDepartmentSums: [],
      addDepartmentType: { pk: [], med: [], pv: [], co: [], stat: [], dm: [] },
      preFileList: [],
      finFileList: [],
    };
  },

  async created() {
    await this.getSearchByUserName();
    await this.resetData();

    this.authGroup.read = this.$store.state.app.userConfig.permissionsKeys?.ContractManagementRead;
    this.authGroup.create = this.$store.state.app.userConfig.permissionsKeys?.ContractManagementCreate;
    this.authGroup.update = this.$store.state.app.userConfig.permissionsKeys?.ContractManagementUpdate;
    this.authGroup.delete = this.$store.state.app.userConfig.permissionsKeys?.ContractManagementDelete;
  },

  methods: {
    expandOver() {
      this.$nextTick(() => {
        this.calcTreeDom();
      });
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    preRemove(file) {
      this.uploadRemove(file, 'preUpload');
    },
    finRemove(file) {
      this.uploadRemove(file, 'finUpload');
    },
    uploadRemove(file, name) {
      return this.$confirm(this.$t('contractManagement.sureDeleteFileTips') + `${file.name}？`, '', {
        confirmButtonText: this.$t('commonVariables.complete'),
        cancelButtonText: this.$t('commonVariables.cancel'),
      }).then(() => {
        if (name === 'preUpload') {
          const delIDx = this.preFileList.findIndex(item => {
            return item.name === file.name;
          });
          this.preFileList.splice(delIDx, 1);
          this.recordForm.preContractUrls.splice(delIDx, 1);
        } else {
          const delIDx = this.finFileList.findIndex(item => {
            return item.name === file.name;
          });
          this.finFileList.splice(delIDx, 1);
          this.recordForm.finalContractUrls.splice(delIDx, 1);
        }
      });
    },
    preBeforeUpload(file) {
      this.existCheckFn(file, 'preUpload');
    },
    finBeforeUpload(file) {
      this.existCheckFn(file, 'finUpload');
    },
    existCheckFn(file, refName /* result */) {
      let existKey = -1;
      if (refName === 'preUpload') {
        existKey = this.preFileList.findIndex(val => val.name === file.name);
      } else {
        existKey = this.finFileList.findIndex(val => val.name === file.name);
      }
      if (~existKey) {
        this.$refs[refName].uploadFiles.splice(
          this.$refs[refName].uploadFiles.findLastIndex(val => val.name === file.name),
          1
        );
        this.$message.error('请不要重复添加相同的文件');
        this.repeatCheck = true;
        return false;
      } else {
        refName === 'preUpload' ? this.preFileList.push(file) : this.finFileList.push(file);
      }
    },
    finUpload(param) {
      this.manualUpload(param, 'finUpload');
    },
    preUpload(param) {
      this.manualUpload(param, 'preUpload');
    },
    manualUpload(param, name) {
      this.$nextTick(() => {
        contractManagement.uploadContract().then(async res => {
          const sts = Object.assign({}, res[1]);
          const client = new OSS({
            accessKeyId: sts.accessId,
            accessKeySecret: sts.accessKeySecret,
            bucket: sts.bucket,
            region: sts.region,
            stsToken: sts.securityToken,
          });
          const result = await client.multipartUpload(sts.dir + param.file.name, param.file, {
            // progress: progress
          });
          if (!this.repeatCheck) {
            name === 'preUpload'
              ? this.recordForm.preContractUrls.push(result.res.requestUrls[0])
              : this.recordForm.finalContractUrls.push(result.res.requestUrls[0]);
          }
          this.repeatCheck = false;
        });
      });
    },

    // 上传合同
    resetForm() {
      this.$refs['dataForm'].resetFields();
      this.memberSelected = [];
      this.showMemberSelectedName = [];
      this.dateVal = [];
    },
    addContract() {
      this.addItem();
      this.drawerVisable = true;
    },
    addItem() {
      this.addDepartmentSums.push({
        group: '',
        boId: '',
        pmId: '',
      });
    },
    closeDrawer(warnTips) {
      this.$confirm(warnTips, '', {
        confirmButtonText: this.$t('commonVariables.complete'),
        cancelButtonText: this.$t('commonVariables.cancel'),
      }).then(() => {
        this.cancelSave();
      });
    },
    cancelSave() {
      this.showMemberSelectedName = [];
      this.memberSelected = [];
      this.addDepartmentSums = [];
      this.preFileList = [];
      this.finFileList = [];
      this.recordForm = {
        id: '',
        projectCode: '',
        contractCode: '',
        customerId: '',
        contractType: '',
        name: '',
        shortName: '',
        currency: '',
        amount: 0,
        year: '',
        orderDate: '',
        status: '',
        contractStaffs: [],
        bdIds: [],
        preContractUrls: [],
        finalContractUrls: [],
        period: '',
      };
      this.$refs.preUpload.clearFiles();
      this.$refs.finUpload.clearFiles();
      this.drawerVisable = false;
    },
    //按名字检索用户
    async getSearchByUserName() {
      let [bdData] = await contractManagement.getuserOption({ groupCodeEq: 'bd' });
      let [sponsorData] = await resourceManagement.sponsorData({ page: 1, pagesize: 999999 });
      let [customerData] = await resourceManagement.customersData({ page: 1, pagesize: 999999 });
      this.sponsorOption = [...sponsorData.sponsors];
      if (bdData) {
        this.allBd = [...bdData.users];
      }
      if (customerData) {
        this.customerOptions = [...customerData.customers];
      }
    },
    pageChange(type, vals) {
      if (type === 'size') {
        this.dataForm.pageSize = vals;
      } else {
        this.dataForm.page = vals;
      }
      this.refreshData();
    },
    setCustomerName(item) {
      this.recordForm.customerName = this.customerOptions.find(val => val.id == item).name;
    },
    translateUrl(url) {
      let finishUrl = new URL(url);
      finishUrl.hash = '';
      finishUrl.search = '';
      finishUrl = finishUrl.toString();
      const encodedUrl = decodeURI(finishUrl);
      return encodedUrl;
    },
    async editRow(row) {
      this.recordForm = Object.assign({}, row);
      this.addDepartmentSums = [...row.contractStaffs];
      await this.addDepartmentSums.map(async item => {
        if (this.addDepartmentType[item.group] && this.addDepartmentType[item.group].length) {
          return;
        }
        let [options] = await contractManagement.getuserOption({ groupCodeEq: item.group });
        this.addDepartmentType[item.group] = [...options.users];
      });
      this.memberSelected = [...row.bdIds];
      this.showMemberSelectedName = [...row.bdNames];
      console.log(row.preContractUrls);

      if (row.preContractUrls.length) {
        row.preContractUrls.filter(val => {
          let finUrl = this.translateUrl(val);
          this.preFileList.push({ name: finUrl.split('/')[finUrl.split('/').length - 1], url: finUrl });
        });
      }
      if (row.finalContractUrls.length) {
        row.finalContractUrls.filter(val => {
          let finUrl = this.translateUrl(val);
          this.finFileList.push({ name: finUrl.split('/')[finUrl.split('/').length - 1], url: finUrl });
        });
      }

      this.drawerVisable = true;
    },
    changeBdSelected(vals, ids) {
      this.showMemberSelectedName = [...vals];
      this.recordForm.bdIds = [...ids];
      this.memberSelected = [...ids];
    },
    async deleteData(id) {
      try {
        await contractManagement.delContract(id);
        this.resetData();
      } catch (err) {
        this.$message.error('保存项目失败！');
        return;
      }
    },
    filterData() {
      this.dataForm.page = 1;
      this.dataForm.pageSize = 20;
      this.count = 0;
      this.refreshData();
    },
    async refreshData() {
      if (!this.authGroup.read) {
        this.errImg = require('@/assets/noquanxian.png');
        this.errInfo = '抱歉，您暂无权限哦～';
        this.shouldShowErrimg = true;
        this.$message.closeAll();
        return;
      }
      this.tableLoading = true;
      let q = Object.assign({}, this.dataForm);
      q.orderDateGte = this.dateVal?.[0] ? dayFormat(this.dateVal[0], 'YYYY-MM-DD') : '';
      q.orderDateLte = this.dateVal?.[1] ? dayFormat(this.dateVal[1], 'YYYY-MM-DD') : '';
      contractManagement.getContractList(q).then(res => {
        if (res[2].status !== 200) {
          return;
        }
        if (!res[0].contracts || !res[0].contracts.length > 0) {
          this.serviceTableData = [];
          this.tableLoading = false;
          this.showEmpty = true;
          return;
        }
        const result = res[0].contracts;
        /*           this.countProjSummary = result.length; */
        this.serviceTableData = [...result];

        this.tableLoading = false;
        this.showEmpty = false;
        this.count = res[0].count ? res[0].count : 0;
      });
    },
    //保存服务项
    async saveAllServiceItems() {
      this.$refs.recordForm.validate(async valid => {
        if (!valid) {
          return;
        }
        let isFillDepartment = false;
        this.addDepartmentSums.map((i, idx) => {
          for (let i in this.addDepartmentSums[idx]) {
            if (!this.addDepartmentSums[idx][i]) {
              if (i == 'group') isFillDepartment = true;
              this.addDepartmentSums[idx][i] = null;
            }
          }
        });
        if (isFillDepartment) {
          this.$message.error('添加部门一栏中，部门选项必填！');
          return;
        }
        this.recordForm.customerName = '';
        const submitForm = Object.assign({}, this.recordForm);
        submitForm.bdNames = null;

        submitForm.contractStaffs = [...this.addDepartmentSums];
        const editId = submitForm?.id;
        const res = editId ? await contractManagement.updateContract(submitForm, editId) : await contractManagement.createContract(submitForm);
        this.dialogLoading = false;
        this.confirmBtnText = '确定';

        if (!res[1].code && res[2].status !== 404) {
          this.cancelSave();
          await this.refreshData();
          this.$message.success(this.$t('commonVariables.submittedSuccessfully'));
        } else if (res[1].metadata) {
          const errData = res[1].metadata;
          errData.code ? (this.codeError = true) : null;
          errData.name ? (this.nameError = true) : null;
        }
      });
    },
    removeDepartment(idx) {
      this.addDepartmentSums.splice(idx, 1);
    },
    resetData() {
      this.dataForm = {
        page: 1,
        pageSize: 20,
        contractCodeCont: '',
        projectCodeCont: '',
        customerIdEq: '',
        contractTypeEq: '',
        statusEq: '',
        bdIdEq: '',
        nameCont: '',
      };

      this.dateVal = [];

      this.count = 0;
      this.refreshData();
    },
    async chooseDp(item, idx) {
      this.addDepartmentSums[idx].boId = null;
      this.addDepartmentSums[idx].pmId = null;
      if (this.addDepartmentType[item].length) {
        return;
      }
      let [options] = await contractManagement.getuserOption({ groupCodeEq: item });
      this.addDepartmentType[item] = [...options.users];
    },
    handleSizeChange(val) {
      this.dataForm.pageSize = val;
      this.refreshData();
    },
    handleCurrentChange(val) {
      this.dataForm.page = val;
      this.refreshData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/compsStyle/projectStatement.scss';
@import '@/styles/compsStyle/resource.scss';
#contractManagement {
  @include resourceStyle;
  padding: 14px 0;
  padding-bottom: 0;
  border-radius: 2px;
  margin: 0 16px;
  margin-right: 24px;
  margin-top: 0;
  overflow-y: hidden;
  ::v-deep .top-box {
    display: flex;
    border-bottom: 1px solid #e8eaec;
    min-height: 56px;
    align-items: center;
  }
  .heat-shield {
    background: #f8f8f8f9;
    height: 16px;
    width: 100%;
  }
  ::v-deep .el-form-item__label {
    line-height: 32px;
    font-weight: normal;
    color: #515a6e;
  }
  ::v-deep .el-input__prefix {
    top: -3px;
  }
  .headerContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: auto;
    .top-header-tit {
      font-size: 16px;
      font-weight: 600;
      color: #19233b;

      .expandControl {
        display: inline-block;
        margin-left: 12px !important;
        color: #409eff;
        cursor: pointer;
        font-size: 14px;
        span {
          padding-right: 5px;
        }
      }
    }

    .editContract {
      display: inline-block;
      margin: 0;
      padding-left: 32px;
      font-size: 16px;
      font-weight: 700;
      color: #19233b;
    }
    .buttonGroup {
      margin-right: 32px;
    }
  }

  .searchFormContainer {
    background: #ffffff;
    border-radius: 2px;
    .dataForm {
      padding: 0 32px;
      display: flex;
      flex: 1;
      justify-content: flex-start;
      flex-wrap: wrap;
      font-size: 14px;
      .el-form-item {
        margin: 0;
        flex: 0;
        margin-right: 48px;
        min-width: 260px;

        padding: 10px 0;
        ::v-deep .el-select {
          width: 100%;
        }
        ::v-deep .el-form-item__content {
          line-height: 32px;
        }
        ::v-deep .el-input__inner {
          color: #515a6e;
          height: 32px;
          line-height: 32px;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          font-size: 14px;
        }
        ::v-deep .el-form-item__label {
          color: #515a6e;
          font-weight: normal;
          font-size: 14px;
          height: 20px;
          padding: 0;
          line-height: 20px;
          margin-top: 0;
          margin-bottom: 7px;
        }

        .el-date-picker {
          height: 32px;
          line-height: 32px;
        }
      }

      .button-group {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        padding-right: 0;

        .el-button {
          height: 32px;
          padding: 6px 20px;
        }
      }
    }
    .heat-shield {
      background: #f8f8f8f9;
      height: 16px;
      width: 100%;
    }
  }

  .record {
    ::v-deep .drawerHeaderContainer {
      border-bottom: 1px solid #e8eaec;
      margin-bottom: 0;
      padding-bottom: 0;
      padding: 0 20px;
      padding-left: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        font-size: 19px;
        font-weight: 700;
        color: #19233b;
      }
      .addContract-button-group {
        .el-button {
          width: 70px;
          height: 32px;
        }
      }
    }
    .closeIcon {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 25px;
      font-size: 18px;
    }
    .recordForm {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-left: 32px;
      padding-top: 14px;
      ::v-deep .el-input__inner {
        height: 32px;
        width: 248px;
      }

      .rightForm {
        margin-left: 36px;
      }
      .el-form-item {
        margin: 0;
        padding: 10px 0;
        text-align: left;
      }
    }

    .addDepartment {
      margin: 0 12px;
      margin-left: 28px;
      .addHeaderContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          font-weight: 600;
        }
        .right {
          cursor: pointer;
          .addIcon {
            color: #1890ff;
            margin-right: 4px;
            font-size: 22px;
            line-height: 28px;
            vertical-align: middle;
          }
        }
      }
      .addContainer {
        overflow: hidden;
        margin: 0 auto;
        width: 100%;
        max-height: 260px;
        background: #f8f8f9;
        border-radius: 4px;
        overflow-y: auto;
        .labelContainer {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          padding: 10px 0;
          p {
            width: 224px;
            margin: 0 !important;
          }
          .a-placeholder {
            width: 16px;
          }
        }
        .mainContainer {
          padding: 8px 0;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;

          .department,
          .bo,
          .pm {
            width: 210px;
          }
          .removeOp {
            cursor: pointer;
            color: #1890ff;
            font-size: 22px;
            margin-right: 20px;
          }
        }
      }
    }
    .uploadForm {
      ::v-deep .el-upload-list__item {
        transition: none !important;
      }
      .el-form-item {
        padding: 14px 0;
      }
    }
  }
}
/* @media screen and (min-width: 900px) and (max-width: 1550px) {
  #contractManagement {
    @include resourceStyle;
    padding: 0 !important;
    padding-bottom: 0;
    border-radius: 2px;
    margin: 0 16px !important;
    margin-right: 12px !important;
    margin-top: 0;
    overflow-y: hidden;
  }
} */
</style>
